import React, { createContext, useContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	// Retrieve the token and user_id from local storage if they exist
	const [token, setToken] = useState(() => localStorage.getItem("accessToken"));
	const [userId, setUserId] = useState(() => localStorage.getItem("user_id"));

	// Save the token and user_id in the state and local storage
	const login = (accessToken, user_id) => {
		setToken(accessToken);
		setUserId(user_id);
		localStorage.setItem("accessToken", accessToken);
		localStorage.setItem("user_id", user_id);
	};

	// Clear token and user_id from both state and local storage on logout
	const logout = () => {
		setToken(null);
		setUserId(null);
		localStorage.removeItem("accessToken");
		localStorage.removeItem("user_id");
	};

	return (
		<AuthContext.Provider value={{ token, userId, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
