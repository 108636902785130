import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        if ((response.data.code && (response.data.code === 401)))
            {
            localStorage.removeItem('accessToken');
            window.location.href = '/';
        }
        return response;
    },
    error => {
        console.log(error)
        if ((error.response.data.code && (error.response.data.code === 401 || error.response.data.code === 400)) || error.response.data.code === 301)
            {
            localStorage.removeItem('accessToken');
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
