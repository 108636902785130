import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { useToast } from '../../Context/ToastContext';
import { FaCaretDown, FaUserPlus } from 'react-icons/fa';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';

const expectedFields = [
    "first_name", "last_name", "email", "phone_number",
    "address", "city", "state", "zip_code", "loan",
    "buyer_agent", "employee_type", "closed_date",
    "insurance_agent", "processor", "listing_agent",
    "refi_target_rate", "rate", "credit_score", "file_number"
];

const loanTypeMap = {
    FHA: "1",
    USDA: "2",
    Conventional: "3",
    VA: "4",
    Reverse: "5",
    "Non-Qm": "6",
};

const UploadBulkModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [mapping, setMapping] = useState({});
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const nav = useNavigate();
    const modalRef = useRef(); // Create a ref for the modal

    const { generateToast } = useToast();

    const openModal = () => {
        setIsModalOpen(true);
        resetModalState();
        setIsDropdownOpen(false)
    };

    const closeModal = () => setIsModalOpen(false);

    const resetModalState = () => {
        setStep(1);
        setFile(null);
        setHeaders([]);
        setMapping({});
        setCustomers([]);
        setError("");
    };

    const mapLoanType = (loanType) => loanTypeMap[loanType] || "3"; // Default to "Conventional"

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
        setIsLoading(true);
        setError("");

        Papa.parse(uploadedFile, {
            complete: (results) => {
                if (results.data && results.data.length > 0) {
                    const parsedHeaders = Array.isArray(results.data[0])
                        ? results.data[0]
                        : Object.keys(results.data[0]);
                    setHeaders(parsedHeaders);
                    setStep(2);
                } else {
                    setError("The CSV file is empty or invalid");
                }
                setIsLoading(false);
            },
            header: true,
            skipEmptyLines: true,
            error: (error) => {
                setError(`Error parsing CSV: ${error.message}`);
                setIsLoading(false);
            },
        });
    };

    const handleMapping = (expectedField, csvField) => {
        setMapping((prev) => ({ ...prev, [expectedField]: csvField }));
    };

    const getClosedDate = () => {
        const date = new Date();
        date.setMonth(date.getMonth() + 3);
        return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const processCustomers = () => {
        setIsLoading(true);
        Papa.parse(file, {
            complete: (results) => {
                if (results.data && results.data.length > 0) {
                    const mappedCustomers = results.data.map(row => generateCustomerObject(row));
                    setCustomers(mappedCustomers);
                    setStep(3);
                } else {
                    setError("No valid data found in the CSV file");
                }
                setIsLoading(false);
            },
            header: true,
            skipEmptyLines: true,
            error: (error) => {
                setError(`Error processing CSV: ${error.message}`);
                setIsLoading(false);
            },
        });
    };

    const generateCustomerObject = (row) => {
        const customer = {
            employee_type: "2",
            closed_date: getClosedDate(),
        };

        Object.entries(mapping).forEach(([expectedField, csvField]) => {
            if (csvField) {
                customer[expectedField] = expectedField === "loan"
                    ? mapLoanType(row[csvField])
                    : row[csvField] || "";
            }
        });

        return customer;
    };

    const submitAllCustomers = async () => {
        setIsLoading(true);
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            generateToast("Access token not found. Please log in again.", "error");
            setIsLoading(false);
            return;
        }

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };

        let successCount = 0;
        let errorCount = 0;

        for (const customer of customers) {
            try {
                const response = await axiosInstance.post("https://djangobk.lenditcrm.com/customer/add/", customer, { headers });
                if (response.data.code === 200) successCount++;
                else errorCount++;
            } catch (error) {
                errorCount++;
            }
        }

        generateToast(`Bulk upload completed. Successfully added: ${successCount}, Errors: ${errorCount}`, successCount > 0 ? "success" : "error");
        setIsLoading(false);
    };

    // Close modal on outside click
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (modalRef.current && !modalRef.current.contains(event.target)) {
    //             closeModal();
    //         }
    //     };

    //     if (isModalOpen) {
    //         document.addEventListener("mousedown", handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [isModalOpen]);

    return (
        <div>
            <div>
                <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                    <FaUserPlus className="mr-2" />
                    Manage Customer
                    <FaCaretDown className="ml-2" />
                </button>
            </div>
            {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical">
                        <button
                            onClick={() => nav("/addcustomer")}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                            Add Customer
                        </button>
                        <button
                            onClick={openModal}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                            Import Bulk
                        </button>
                    </div>
                </div>
            )}
                {isModalOpen && (
                    <div className="fixed  inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                        <div ref={modalRef} className="relative w-3/4 p-5 border shadow-lg rounded-md bg-white z-60">
                            <div className="mt-3">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                                    CSV Upload and Mapping Wizard - Step {step} of 3
                                </h3>
                                {step === 1 && (
                                    <div>
                                        <p className="text-sm text-gray-500 mb-4">Upload your CSV file</p>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileUpload}
                                            className="block w-full text-sm text-gray-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-full file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-blue-50 file:text-blue-700
                                                hover:file:bg-blue-100"
                                        />
                                    </div>
                                )}
                                {step === 2 && (
                                    <div>
                                        <p className="text-sm text-gray-500 mb-4">Map your CSV fields to our expected fields</p>
                                        <div className="grid grid-cols-2 gap-4">
                                            {expectedFields.map((expectedField) => (
                                                <div key={expectedField} className="mb-2">
                                                    <label className="block text-sm font-medium text-gray-700">{expectedField}</label>
                                                    <select
                                                        onChange={(e) => handleMapping(expectedField, e.target.value)}
                                                        className="mt-1 block w-full border border-gray-300 rounded-md"
                                                    >
                                                        <option value="">Select field...</option>
                                                        {headers.map((header) => (
                                                            <option key={header} value={header}>
                                                                {header}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ))}
                                        </div>
                                        <button onClick={processCustomers} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Next
                                        </button>
                                    </div>
                                )}
                                {step === 3 && (
                                    <div>
                                        <p className="text-sm text-gray-500 mb-4">Review and submit your customers</p>
                                        <ul>
                                            {customers.map((customer, index) => (
                                                <li key={index} className="mb-2 text-gray-800">{JSON.stringify(customer)}</li>
                                            ))}
                                        </ul>
                                        <button onClick={submitAllCustomers} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                            Submit
                                        </button>
                                    </div>
                                )}
                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex justify-end mt-4">
                                    <button
                                        onClick={closeModal}
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default UploadBulkModal;
