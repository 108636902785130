import React, { useState, useEffect } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import axiosInstance from "../../axiosInstance";
import Header from "../Header";
import TotalLoansClosedReport from "./conpoonents/TotalLoansClosedReport";
import PipelineReport from "./conpoonents/PipelineReport";

const loanTypes = {
	1: "FHA",
	2: "USDA",
	3: "Conventional",
};

// Function to get the week number of a date
const getWeekNumber = (date) => {
	const startOfYear = new Date(date.getFullYear(), 0, 1);
	const pastDaysOfYear = (date - startOfYear) / 86400000;
	return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

// Function to transform the loan data into chart-compatible format
const transformLoanData = (data, timeFrame) => {
	const groupedData = {};

	// biome-ignore lint/complexity/noForEach: <explanation>
	data.forEach((item) => {
		const date = new Date(item.closed_date);

		let key; // Key for grouping (month, week, or year)

		if (timeFrame === "Weekly") {
			const weekNumber = getWeekNumber(date);
			key = `Week ${weekNumber} (${date.getFullYear()})`;
		} else if (timeFrame === "Yearly") {
			key = date.getFullYear().toString();
		} else {
			// Default to Monthly
			key = date.toLocaleString("default", { month: "short" });
		}

		if (!groupedData[key]) {
			groupedData[key] = { period: key, loans: 0 };
		}
		groupedData[key].loans += item.loan ? 1 : 0;
	});

	return Object.values(groupedData);
};

// Function to transform the status data into chart-compatible format
const transformStatusData = (data) => {
	const statusCounts = {};

	data.forEach((item) => {
		// Check if the status exists; if not, initialize it
		if (item.status) {
			statusCounts[item.status] = (statusCounts[item.status] || 0) + 1;
		}
	});

	// Convert statusCounts object to an array
	return Object.keys(statusCounts).map((status) => ({
		status,
		count: statusCounts[status],
	}));
};

const fetchCustomerList = async (setRowData) => {
	try {
		const response = await axiosInstance.get(
			"https://djangobk.lenditcrm.com/customer/list/",
		);
		if (response.data.code === 200) {
			setRowData(response.data.data);
		} else {
			console.error("Error fetching customer list:", response.statusText);
		}
	} catch (error) {
		console.error("Error:", error);
	}
};

export default function LoanChart() {
	const [timeFrame, setTimeFrame] = useState("Monthly");
	const [rowData, setRowData] = useState([]);
	const [loanChartData, setLoanChartData] = useState([]);
	const [statusChartData, setStatusChartData] = useState([]);
	const [selectedLoanType, setSelectedLoanType] = useState("all");

	useEffect(() => {
		fetchCustomerList(setRowData);
	}, []);

	useEffect(() => {
		updateLoanChartData(rowData);
		updateStatusChartData(rowData);
	}, [rowData, selectedLoanType, timeFrame]);

	const updateLoanChartData = (data) => {
		if (selectedLoanType === "all") {
			setLoanChartData(transformLoanData(data, timeFrame));
		} else {
			const filteredData = data.filter(
				(row) => row.loan.toString() === selectedLoanType,
			);
			setLoanChartData(transformLoanData(filteredData, timeFrame));
		}
	};

	const updateStatusChartData = (data) => {
		setStatusChartData(transformStatusData(data));
	};

	const onLoanTypeChange = (event) => {
		setSelectedLoanType(event.target.value);
	};

	return (
		<>
			<Header />
			<div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-5xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
					<div className="p-8">
						<h2 className="text-2xl font-semibold text-gray-800 mb-6">
							Loan Distribution Chart
						</h2>
						<div className="flex flex-col sm:flex-row justify-between items-center mb-8">
							<div className="flex items-center mb-4 sm:mb-0">
								<div className="w-4 h-4 rounded-md bg-teal-500 mr-2"></div>
								<span className="text-sm font-medium text-gray-600">Loans</span>
							</div>

							<div className="flex items-center space-x-2 mb-4 sm:mb-0">
								{["Weekly", "Monthly", "Yearly"].map((frame) => (
									<button
										key={frame}
										className={`px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ${
											timeFrame === frame
												? "bg-blue-500 text-white"
												: "bg-gray-200 text-gray-700 hover:bg-gray-300"
										}`}
										onClick={() => {
											setTimeFrame(frame);
											updateLoanChartData(rowData);
										}}
									>
										{frame}
									</button>
								))}
							</div>

							<div>
								<select
									onChange={onLoanTypeChange}
									value={selectedLoanType}
									className="p-2 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
								>
									<option value="all">All Types</option>
									<option value="1">FHA</option>
									<option value="2">USDA</option>
									<option value="3">Conventional</option>
									<option value="4">VA</option>
									<option value="5">Reverse</option>
									<option value="6">Non-QM</option>
								</select>
							</div>
						</div>

						<div
							className="bg-gray-50 p-4 rounded-lg mb-12"
							style={{ height: 400 }}
						>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									layout="vertical"
									data={loanChartData}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" horizontal={false} />
									<XAxis type="number" />
									<YAxis dataKey="period" type="category" width={80} />
									<Tooltip
										contentStyle={{
											backgroundColor: "rgba(255, 255, 255, 0.8)",
											border: "none",
											borderRadius: "4px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									/>
									<Bar
										dataKey="loans"
										fill="#14b8a6"
										barSize={20}
										radius={[0, 4, 4, 0]}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>

						<div
							className="bg-gray-50 p-4 rounded-lg m-4"
							style={{ height: 400 }}
						>
							<h2 className="text-2xl font-bold mb-4">Pipeline Report</h2>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									layout="vertical"
									data={statusChartData}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" horizontal={false} />
									<XAxis type="number" />
									<YAxis dataKey="status" type="category" width={150} />
									<Tooltip
										contentStyle={{
											backgroundColor: "rgba(255, 255, 255, 0.8)",
											border: "none",
											borderRadius: "4px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									/>
									<Bar
										dataKey="count"
										fill="#4a90e2"
										barSize={20}
										radius={[0, 4, 4, 0]}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<TotalLoansClosedReport data={rowData} />
							<PipelineReport data={rowData} />
						</div>

						{/* 
						<div className="flex flex-col sm:flex-row justify-between items-center mb-8">
							<div className="flex items-center mb-4 sm:mb-0">
								<div className="w-4 h-4 rounded-md bg-teal-500 mr-2"></div>
								<span className="text-sm font-medium text-gray-600">Loans</span>
							</div>

							<div className="flex items-center space-x-2 mb-4 sm:mb-0">
								{["Weekly", "Monthly", "Yearly"].map((frame) => (
									<button
										key={frame}
										className={`px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ${
											timeFrame === frame
												? "bg-blue-500 text-white"
												: "bg-gray-200 text-gray-700 hover:bg-gray-300"
										}`}
										onClick={() => {
											setTimeFrame(frame);
											updateLoanChartData(rowData);
										}}
									>
										{frame}
									</button>
								))}
							</div>

							<div>
								<select
									onChange={onLoanTypeChange}
									value={selectedLoanType}
									className="p-2 border rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
								>
									<option value="all">All Types</option>
									<option value="1">FHA</option>
									<option value="2">USDA</option>
									<option value="3">Conventional</option>
								</select>
							</div>
						</div>

						
						<div
							className="bg-gray-50 p-4 rounded-lg mb-12"
							style={{ height: 400 }}
						>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									layout="vertical"
									data={loanChartData}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" horizontal={false} />
									<XAxis type="number" />
									<YAxis dataKey="period" type="category" width={80} />
									<Tooltip
										contentStyle={{
											backgroundColor: "rgba(255, 255, 255, 0.8)",
											border: "none",
											borderRadius: "4px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									/>
									<Bar
										dataKey="loans"
										fill="#14b8a6"
										barSize={20}
										radius={[0, 4, 4, 0]}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>

				
						<div className="bg-gray-50 p-4 rounded-lg" style={{ height: 400 }}>
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									layout="vertical"
									data={statusChartData}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" horizontal={false} />
									<XAxis type="number" />
									<YAxis dataKey="status" type="category" width={150} />
									<Tooltip
										contentStyle={{
											backgroundColor: "rgba(255, 255, 255, 0.8)",
											border: "none",
											borderRadius: "4px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									/>
									<Bar
										dataKey="count"
										fill="#4a90e2"
										barSize={20}
										radius={[0, 4, 4, 0]}
									/>
								</BarChart>
							</ResponsiveContainer>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}
