import React, { useEffect, useRef } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { X } from "react-feather";
import axiosInstance from "../../axiosInstance";

const Delete = ({ isOpen, closeModal, id, refresh }) => {
	const modalRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	function onDelete() {
		let url;
		url = `https://djangobk.lenditcrm.com/user/delete/?id=${id}`;
		axiosInstance
			.get(url)
			.then((response) => {
				alert("User Deleted");
				refresh(true);
				console.log(response);
			})
			.catch((error) => {
				console.error(error);
			});

		closeModal();
	}

	return (
		<div
			className={`fixed inset-0 flex justify-center items-center transition-colors ${
				isOpen ? "visible bg-black/20" : "invisible"
			}`}
			onClick={closeModal}
		>
			<div
				className={`bg-white rounded-xl shadow p-6 transition-all ${
					isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
				}`}
				onClick={(e) => e.stopPropagation()}
			>
				<button
					onClick={closeModal}
					className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
				>
					<X />
				</button>
				<div className="text-center w-56">
					<AiOutlineExclamationCircle className="text-red-500 text-2xl" />
					<div className="mx-auto my-4 w-48">
						<h3 className="font-bold text-xl">Delete User Record</h3>
						<p className="text-sm text-gray-500">
							Are you sure you want to delete this item?
						</p>
					</div>
					<div className="flex gap-4">
						<button
							className="btn btn-light w-full text-black border border-black hoverbg-gray-300 font-bold py-1 px-4 rounded-lg mt-2"
							onClick={closeModal}
						>
							Cancel
						</button>
						<button
							onClick={onDelete}
							className="btn btn-danger w-full bg-red-500 hoverbg-red-700 text-white font-bold py-1 px-4 rounded-lg mt-2 ml-2"
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Delete;
