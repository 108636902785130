import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/LoginPage";
import User from "./Pages/User/UserListingPage";
import Listing from "./Pages/ListingPage";
import AddCustomer from "./Pages/Customer/AddCustomer";
import Note from "./Pages/Note";
import NotFound from "./Pages/NotFound";
import Register from "./Pages/Auth/Register";
import Pipeline from "./Pages/Pipeline/Pipeline";
import Reports from "./Pages/Reports/Reports";
import ActiveFiles from "./Pages/ActiveFiles/ActiveFiles";
import DashboardPage from "./Pages/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./components/AuthToken";
import PasswordResetConfirm from "./Pages/ResetPassword";

function App() {
	return (
		<AuthProvider>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route
							path="/addcustomer"
							element={
								<ProtectedRoute>
									<AddCustomer />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/user"
							element={
								<ProtectedRoute>
									<User />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/listing"
							element={
								<ProtectedRoute>
									<Listing />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/notes"
							element={
								<ProtectedRoute>
									<Note />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/reports"
							element={
								<ProtectedRoute>
									<Reports />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/activefiles"
							element={
								<ProtectedRoute>
									<ActiveFiles />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/dashboard"
							element={
								<ProtectedRoute>
									<DashboardPage />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/pipeline"
							element={
								<ProtectedRoute>
									<Pipeline />
								</ProtectedRoute>
							}
						/>
						<Route path="*" element={<NotFound />} />
						<Route path="/user/reset/:uid/:token" element={<PasswordResetConfirm/>} />

					</Routes>
				</BrowserRouter>
			</div>
		</AuthProvider>
	);
}

export default App;
