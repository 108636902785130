import React, { useState, useEffect } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

const loanTypes = {
	1: "FHA",
	2: "USDA",
	3: "Conventional",
	4: "VA",
	5: "Reverse",
	6: "Non-Qm",
};

const TotalLoansClosedReport = ({ data }) => {
	const [timeFrame, setTimeFrame] = useState("currentMonth");
	const [filteredData, setFilteredData] = useState([]);

	const loanTypes = {
		1: "FHA",
		2: "USDA",
		3: "Conventional",
		4: "VA",
		5: "Reverse",
		6: "NonQM", // Make sure it's "NonQM" consistently
	};

	const filterDataByTimeFrame = (selectedTimeFrame) => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth();

		return data.filter((loan) => {
			const closedDate = new Date(loan.closed_date);
			switch (selectedTimeFrame) {
				case "currentMonth":
					return (
						closedDate.getMonth() === currentMonth &&
						closedDate.getFullYear() === currentYear
					);
				case "previousMonth":
					return (
						closedDate.getMonth() === (currentMonth - 1 + 12) % 12 &&
						(currentMonth === 0
							? closedDate.getFullYear() === currentYear - 1
							: closedDate.getFullYear() === currentYear)
					);
				case "yearToDate":
					return closedDate.getFullYear() === currentYear;
				case "previousYear":
					return closedDate.getFullYear() === currentYear - 1;
				default:
					return true;
			}
		});
	};

	const processData = (filteredLoans) => {
		const processedData = filteredLoans.reduce((acc, loan) => {
			const date = new Date(loan.closed_date);
			const monthYear = `${date.toLocaleString("default", {
				month: "short",
			})} ${date.getFullYear()}`;

			if (!acc[monthYear]) {
				acc[monthYear] = {
					monthYear,
					FHA: 0,
					USDA: 0,
					Conventional: 0,
					VA: 0,
					NonQM: 0, // Standardized the key to "NonQM"
					Reverse: 0,
				};
			}

			const loanType = loanTypes[loan.loan]; // Get the correct loan type
			if (loanType) {
				acc[monthYear][loanType]++; // Increment the correct loan type
			}

			return acc;
		}, {});

		return Object.values(processedData).sort(
			(a, b) => new Date(a.monthYear) - new Date(b.monthYear),
		);
	};

	useEffect(() => {
		const filtered = filterDataByTimeFrame(timeFrame);
		const processed = processData(filtered);
		console.log("Processed Data: ", processed);
		setFilteredData(processed);
	}, [data, timeFrame]);

	return (
		<div className="bg-white p-6 rounded-lg shadow-md">
			<h2 className="text-2xl font-bold mb-4">Total Loans Closed by Month</h2>
			<div className="mb-4">
				<label
					htmlFor="timeFrame"
					className="block text-sm font-medium text-gray-700"
				>
					Select Time Frame:
				</label>
				<select
					id="timeFrame"
					value={timeFrame}
					onChange={(e) => setTimeFrame(e.target.value)}
					className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
				>
					<option value="currentMonth">Current Month</option>
					<option value="previousMonth">Previous Month</option>
					<option value="previousYear">Previous Year</option>
				</select>
			</div>
			<ResponsiveContainer width="100%" height={400}>
				<BarChart data={filteredData}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="monthYear" />
					<YAxis />
					<Tooltip />
					<Legend />
					{Object.keys(loanTypes).map((key) => (
						<Bar
							key={key}
							dataKey={loanTypes[key]}
							fill={getColorForLoanType(loanTypes[key])}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

// Function to get color based on loan type
const getColorForLoanType = (loanType) => {
	const colors = {
		FHA: "#8884d8",
		USDA: "#82ca9d",
		Conventional: "#ffc658",
		VA: "#ff7300",
		NonQm: "#a3aa0d", // Updated this to match "NonQm" from loanTypes
		Reverse: "#f241c7",
	};
	return colors[loanType] || "#000"; // Default color if not found
};

export default TotalLoansClosedReport;
