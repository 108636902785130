import React, { useState, useEffect } from "react";
import Header from "../Header";
import axiosInstance from "../../axiosInstance";

const Header2 = () => (
	<>
		<Header />
		<h1 className="text-3xl font-bold">Dashboard</h1>
	</>
);

const Card = ({ title, count, children }) => (
	<div className="bg-white rounded-lg shadow-md p-4">
		<div className="flex justify-between items-center mb-4">
			<h2 className="text-lg font-semibold">
				{title} <span className="text-gray-500 text-sm">{count}</span>
			</h2>
			{/* <div className="flex space-x-2">
				<button className="text-gray-400 hover:text-gray-600">+</button>
				<button className="text-gray-400 hover:text-gray-600">•••</button>
			</div> */}
		</div>
		{children}
	</div>
);

const Tag = ({ color, children }) => (
	<span className={`px-2 py-1 rounded-full text-xs font-medium ${color}`}>
		{children}
	</span>
);

const Avatar = ({ letter, color }) => (
	<div
		className={`w-6 h-6 rounded-full flex items-center justify-center text-white text-xs font-medium ${color}`}
	>
		{letter}
	</div>
);

const TaskItem = ({ title, tags, avatar, date }) => (
	<div className="border-b border-gray-200 py-3 last:border-b-0">
		<div className="flex items-center space-x-2 mb-1">
			<input type="checkbox" className="rounded text-blue-500" />
			<span className="font-medium">{title}</span>
		</div>
		<div className="flex items-center justify-between mt-2">
			<div className="flex space-x-2">
				{tags.map((tag, index) => (
					<Tag key={index} color={tag.color}>
						{tag.text}
					</Tag>
				))}
			</div>
			<div className="flex items-center space-x-2">
				<Avatar letter={avatar.letter} color={avatar.color} />
				<span className="text-sm text-gray-500">{date}</span>
			</div>
		</div>
	</div>
);

// const AddSection = () => (
// 	<div className="bg-white rounded-lg shadow-md p-4 flex items-center justify-center h-full">
// 		<button className="text-gray-400 hover:text-gray-600 text-lg">
// 			+ Add section
// 		</button>
// 	</div>
// );
const LoanCard = ({ loan }) => {
	const getStatusColor = (status) => {
		switch (status) {
			case "Active Lead":
				return "bg-green-200 text-gray-900";
			case "Application Sent":
				return "bg-green-100 text-green-800";
			case "Preapproved":
				return "bg-yellow-100 text-yellow-800";
			case "Underwriting":
				return "bg-blue-100 text-blue-800";
			case "Closed":
				return "bg-gray-300 text-gray-900";
			default:
				return "bg-gray-100 text-gray-800";
		}
	};

	const getLoanTypeColor = (loanType) => {
		switch (loanType) {
			case 1:
				return "bg-red-100 text-red-800"; // FHA
			case 2:
				return "bg-blue-100 text-blue-800"; // USDA
			case 3:
				return "bg-purple-100 text-purple-800"; // Conventional
			default:
				return "bg-gray-100 text-gray-800";
		}
	};

	const getLoanTypeName = (loanType) => {
		switch (loanType) {
			case 1:
				return "FHA";
			case 2:
				return "USDA";
			case 3:
				return "Conventional";
			case 4:
				return "VA";
			case 5:
				return "Reverse";
			case 6:
				return "Non-QM";
			default:
				return "Unknown";
		}
	};

	return (
		<Card
			title={`File ${loan.file_number}`}
			count={`${loan.credit_score} (credit-score)`}
		>
			<TaskItem
				title={`${loan.first_name} ${loan.last_name}`}
				tags={[
					{ color: getStatusColor(loan.status), text: loan.status },
					{
						color: getLoanTypeColor(loan.loan),
						text: getLoanTypeName(loan.loan),
					},
				]}
				avatar={{ letter: loan.first_name[0], color: "bg-purple-500" }}
				date={`Closed: ${new Date(loan.closed_date).toLocaleDateString()}`}
			/>
			<div className="mt-2 text-sm text-gray-600">
				<p>Phone: {loan.phone_number}</p>
				<p>Address: {loan.address}</p>
				<p>Processor: {loan.processor}</p>
				<p>Lender: {loan.lender_name}</p>
			</div>
		</Card>
	);
};
const ClosedFilesSection = ({ closedLoans }) => {
	const getLoanTypeColor = (loanType) => {
		switch (loanType) {
			case 1:
				return "bg-red-100 text-red-800";
			case 2:
				return "bg-blue-100 text-blue-800";
			case 3:
				return "bg-purple-100 text-purple-800";
			default:
				return "bg-gray-100 text-gray-800";
		}
	};

	const getLoanTypeName = (loanType) => {
		switch (loanType) {
			case 1:
				return "FHA";
			case 2:
				return "USDA";
			case 3:
				return "Conventional";
			default:
				return "Unknown";
		}
	};
	return (
		<div className="col-span-full">
			<Card title="Closed Files" count={closedLoans.length}>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
					{closedLoans.map((loan) => (
						<TaskItem
							key={loan.id}
							title={`${loan.first_name} ${loan.last_name} - File ${loan.file_number}`}
							tags={[
								{ color: "bg-gray-100 text-gray-800", text: "Closed" },
								{
									color: getLoanTypeColor(loan.loan),
									text: getLoanTypeName(loan.loan),
								},
							]}
							avatar={{ letter: loan.first_name[0], color: "bg-gray-500" }}
							date={`Closed: ${new Date(
								loan.closed_date,
							).toLocaleDateString()}`}
						/>
					))}
				</div>
			</Card>
		</div>
	);
};

const StatusFilter = ({ selectedStatus, onStatusChange }) => (
	<div className="mb-4">
		<select
			className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
			value={selectedStatus}
			onChange={(e) => onStatusChange(e.target.value)}
		>
			<option value="">All Statuses</option>
			<option value="Active Lead">Active Lead</option>
			<option value="Application Sent">Application Sent</option>
			<option value="Preapproved">Preapproved</option>
			<option value="Underwriting">Underwriting</option>
			<option value="Closed">Closed</option>
		</select>
	</div>
);

export default function LoanDashboardPage() {
	const [loans, setLoans] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState("");

	useEffect(() => {
		const fetchCustomerList = async () => {
			try {
				const response = await axiosInstance.get(
					"https://djangobk.lenditcrm.com/customer/list/",
				);
				if (response.data.code === 200) {
					setLoans(response.data.data);
				} else {
					setError("Error fetching customer list: " + response.statusText);
				}
			} catch (error) {
				setError("Error: " + error.message);
			} finally {
				setLoading(false);
			}
		};

		fetchCustomerList();
	}, []);

	if (loading) {
		return (
			<>
				<Header />
				<div className="min-h-screen bg-gray-100 flex items-center justify-center">
					<div className="loader border-t-2 rounded-full border-gray-500 bg-gray-300 animate-spin aspect-square w-8 flex justify-center items-center text-yellow-700"></div>
				</div>
			</>
		);
	}

	if (error) {
		return (
			<div className="min-h-screen bg-gray-100 flex items-center justify-center">
				<p className="text-xl font-semibold text-red-600">{error}</p>
			</div>
		);
	}

	const currentDate = new Date();
	const filteredLoans = loans.filter(
		(loan) =>
			(selectedStatus === "" || loan.status === selectedStatus) &&
			new Date(loan.closed_date) > currentDate,
	);
	const closedLoans = loans.filter(
		(loan) => new Date(loan.closed_date) <= currentDate,
	);

	return (
		<div className="min-h-screen bg-gray-100">
			<Header />
			<h1 className="text-3xl font-bold p-6">Loan Dashboard</h1>
			<main className="p-6">
				<StatusFilter
					selectedStatus={selectedStatus}
					onStatusChange={setSelectedStatus}
				/>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{filteredLoans.map((loan) => (
						<LoanCard key={loan.id} loan={loan} />
					))}
				</div>
				<div className="mt-8">
					<ClosedFilesSection closedLoans={closedLoans} />
				</div>
			</main>
		</div>
	);
}
