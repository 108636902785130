import React, { useState, useEffect, useRef } from "react";
import { X } from "react-feather";
import axiosInstance from "../../axiosInstance";

const EditUser = ({ isOpen, closeModal, userId, data, refresh }) => {
	const [formData, setFormData] = useState({
		email: data.email,
		first_name: data.first_name,
		last_name: data.last_name,
		username: data.username,
		password: "",
		user_role: data.user_role === "Standard User" ? "2" : "3",
	});

	const modalRef = useRef(null);
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	const handleChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// formData.user_role = parseInt(formData.user_role);
		const updatedFormData = { ...formData };
		if (updatedFormData.password === "") {
			delete updatedFormData.password;
		}
		axiosInstance
			.post("https://djangobk.lenditcrm.com/user/update/", {
				id: userId,
				...updatedFormData,
			})
			.then((response) => {
				alert("User updated");
				console.log(response);
			})
			.catch((error) => {
				console.error(error);
			});

		refresh(true);
		closeModal();
	};

	return (
		<div
			className={`fixed inset-0 flex justify-center items-center transition-colors ${
				isOpen ? "visible bg-black/20" : "invisible"
			}`}
			onClick={closeModal}
		>
			<div
				ref={modalRef}
				className={`bg-white rounded-xl shadow p-6 transition-all ${
					isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
				}`}
				onClick={(e) => e.stopPropagation()}
				style={{ marginTop: "5vh" }}
			>
				<div className="form-container ml-6 mr-6 px-4 py-8">
					<h2 className="flex justify-content-start text-xl font-bold ml-8">
						Edit User
					</h2>
					<button
						onClick={closeModal}
						className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
					>
						<X />
					</button>
					<form onSubmit={handleSubmit} className="bg-white rounded px-4 py-5">
						<div className="input-row w-full px-3 mb-10 mt-4 md:mb-0">
							<input
								type="text"
								id="email"
								name="email"
								placeholder="abc@gmail.com"
								value={formData.email}
								onChange={handleChange}
								className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							/>
						</div>
						<div className="flex flex-wrap">
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="firstName"
									name="first_name"
									placeholder="ali"
									value={formData.first_name}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="lastName"
									name="last_name"
									placeholder="Bilal"
									value={formData.last_name}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="userName"
									name=""
									placeholder="aliBilal"
									value={formData.username}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="password"
									name="password"
									placeholder="abc"
									value={formData.password}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
						</div>
						<h1 className="mt-10 text-2xl text-left font-bold">Roles</h1>
						<h2 className="mt-4 text-2xl text-left">PlatForm Role</h2>
						<div className="input-row w-full md:w-2/3 px-3 mb-10 mt-4 md:mb-0">
							<select
								id="role"
								name="user_role"
								placeholder="Administrator"
								value={formData.user_role}
								onChange={handleChange}
								className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							>
								<option value="2">Administrator</option>
								<option value="3">Standard User</option>
							</select>
						</div>
						<div className="flex justify-end mt-4">
							<button
								className="btn btn-light text-black border border-black hover:bg-gray-300 font-bold py-1 px-4 rounded-lg"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								className="flex text-lg bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-1 px-4 rounded-lg ml-4"
								type="submit"
							>
								Save Changes
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditUser;
