import React, { useState, useContext } from "react";
import {
	FaUser,
	FaClipboard,
	FaNotesMedical,
	FaChartBar,
	FaQuestionCircle,
	FaFile,
	FaCaretDown,
} from "react-icons/fa";
import logo from "../../src/assests/headerLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, useAuth } from "../components/AuthToken";

const Header = () => {
	const navigate = useNavigate();
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const { userId, logout } = useAuth();

	const handleUserClick = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const handleLogout = () => {
		// Call the logout function from AuthContext
		logout();

		// Optionally clear tokens from localStorage (if needed)
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");

		// Navigate back to the home or login page
		// console.log("Logged out successfully");
		navigate("/"); // Change this to the desired route
	};
	return (
		<>
			<div className="flex justify-between items-center w-full h-24  py-10 bg-[#028ec8]">
				{/* Logo on the left */}
				<img
					src={logo}
					alt="Your logo"
					style={{
						height: "64px",
						marginLeft: "32px",
						marginTop: "-20px",
						fill: "white",
					}}
				/>
				{/* Admin on the right */}
				<div className="relative">
					<button
						className="flex items-center text-white space-x-2"
						onClick={handleUserClick}
					>
						<FaUser className="text-2xl" />
						<span>User</span>
						<FaCaretDown />
					</button>
					{isDropdownOpen && (
						<div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
							<button
								className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
								onClick={handleLogout}
							>
								Logout
							</button>
						</div>
					)}
				</div>
			</div>

			{/* Navigation Section */}
			<div className="flex justify-start my-2 items-center bg-white rounded-lg p-1">
				<div className="flex justify-start items-center rounded-lg p-1">
					<NavButton
						href="/dashboard"
						icon={<FaClipboard />}
						text="Dashboard"
					/>
					<NavButton
						href="/listing"
						icon={<FaClipboard />}
						text="Customer Records"
					/>
					{userId == 1 && (
						<NavButton href="/user" icon={<FaUser />} text="User Management" />
					)}

					<NavButton
						href="/activefiles"
						icon={<FaFile />}
						text="Active Files"
					/>
					<NavButton href="/reports" icon={<FaChartBar />} text="Reports" />
					<NavButton href="/pipeline" icon={<FaChartBar />} text="Pipeline" />
					<NavButton href="/help" icon={<FaQuestionCircle />} text="Help" />
				</div>
			</div>
		</>
	);
};

function NavButton({ href, icon, text }) {
	const location = useLocation();
	const isActive = location.pathname === href;

	return (
		<a
			href={href}
			className={`inline-flex items-center justify-center border rounded-md px-4 py-2 mr-2 ${
				isActive
					? "bg-blue-500 text-white"
					: "border-gray-500 text-gray-700 hover:bg-gray-100"
			} transition-colors duration-200 ease-in-out min-w-[140px]`}
		>
			{React.cloneElement(icon, { className: "inline-block mr-2" })}
			{text}
		</a>
	);
}

export default Header;
