import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginbg from "../../src/assests/loginbg.png";
import logo from "../../src/assests/logo.svg";
import { useToast } from "../Context/ToastContext";
import { useAuth } from "../components/AuthToken";
import ForgotPasswordModal from "../components/ForgotPasswordModal";

const Login = () => {
	const nav = useNavigate();
	const [loading, setLoading] = useState(false);
	const { generateToast } = useToast();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const { login } = useAuth(); // Use the login function from context

	const handleTogglePassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			setLoading(true);
			const response = await fetch(
				"https://djangobk.lenditcrm.com/user/login/",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						username,
						password,
					}),
				},
			);
			const responseData = await response.json();
			if (responseData.code === 200) {
				setLoading(false);
				console.log("response",response.data)
				generateToast("Login Successfully", "success");
				// Store access token and user_id in the context
				login(responseData.data.access, responseData.data.user_id);

				nav("/listing");
			} else {
				setLoading(false);
				alert("Invalid username or password");
			}
		} catch (error) {
			setLoading(false);
			console.error("Error:", error);
			generateToast(`Error ${error}`, "error");
			alert("An error occurred. Please try again.");
		}
	};

	return (
		<div className="min-h-screen flex">
			{/* Left side - Blue background with CRM name */}
			<div className="w-1/2 bg-blue-500 flex items-center justify-center">
				<div className="text-white">
					<h1 className="text-6xl font-bold">LENDIT CRM</h1>
					<p className="mt-4 text-xl">POWERED BY MAXARON TECHNOLOGIES</p>
				</div>
			</div>

			{/* Right side - Login form */}
			<div className="w-1/2 flex items-center justify-center">
				<div className="max-w-md w-full space-y-8">
					<div>
						<img src={logo} alt="LendIT CRM Logo" className="w-full" />
					</div>
					<form
						onSubmit={handleSubmit}
						className="max-w-md mx-auto mt-8 space-y-6"
					>
						<div className="space-y-2">
							<label
								htmlFor="username"
								className="block text-sm font-medium text-gray-700"
							>
								User Name <span className="text-red-500">*</span>
							</label>
							<input
								id="username"
								name="username"
								type="text"
								required
								placeholder="Type User Name"
								className="w-full border border-blue-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						<div className="space-y-2">
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password <span className="text-red-500">*</span>
							</label>
							<input
								id="password"
								name="password"
								type="password"
								required
								placeholder="Type Password"
								className="w-full border border-blue-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="flex items-center justify-between mt-4">
						{/* <Link target="_blank" rel="noopener noreferrer" to={"https://djangobk.lenditcrm.com/user/password_reset"}>
						<button
							type="button"
							
							className="font-medium text-blue-600 hover:text-blue-500 text-sm"
						>
							Forgot Password?
						</button>
						</Link> */}
					
						</div>
						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								{loading ? "...Loading" : "Login"}
							</button>
						</div>
					</form>
					<div className="">

					<ForgotPasswordModal/>               
					</div>
					<p className="text-center text-sm text-gray-600">
						
						Don't have an account?{" "}
						<a
							href="/register"
							className="font-medium text-blue-600 hover:text-blue-500"
						>
							Register
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
