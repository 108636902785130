import React, { useState, useEffect, useRef } from "react";
import { X } from "react-feather";
import axiosInstance from "../../axiosInstance";
import emailjs from "emailjs-com";
import { useToast } from "../../Context/ToastContext";
const AddUser = ({ isOpen, closeModal, refresh }) => {
	const { generateToast } = useToast();
	const [formData, setFormData] = useState({
		email: "",
		first_name: "",
		last_name: "",
		username: "",
		password: "",
		confirm_password: "", // Initialize confirm_password
		user_role: "2",
		terms_agreed: true, // Always set terms_agreed to true
	});
	const modalRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	const handleChange = (event) => {
		const { name, value } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
			// If the password is updated, also update confirm_password to match
			confirm_password:
				name === "password" ? value : prevFormData.confirm_password,
		}));
	};
	const sendEmailNotification = (email, first_name, password) => {
		const templateParams = {
			user_name: first_name,
			email: email,
			password: password,
		};

		emailjs
			.send(
				"service_5jqec7b", // Replace with your service ID
				"template_x6fjn0n", // Replace with your template ID
				templateParams,
				"m0ZERoYf4IQTGfBR8", // Replace with your public key
			)
			.then(
				(response) => {
					console.log("SUCCESS!", response.status, response.text);
				},
				(error) => {
					console.error("FAILED...", error);
				},
			);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		formData.user_role = parseInt(formData.user_role);

		try {
			// Try to add the user first
			const response = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/user/register/",
				formData,
			);

			if (response.status === 201) {
				console.log("User added successfully");
				generateToast("User added successfully", true); // Show toast for user addition

				// Try to send email notification
				try {
					await sendEmailNotification(
						formData.email,
						formData.username,
						formData.password,
					);
					generateToast("Email notification sent successfully", "success"); // Show toast for successful email notification
				} catch (emailError) {
					console.error("Error sending email notification:", emailError);
					generateToast("Error sending email notification", "error"); // Show toast if email sending fails
				}

				closeModal();
			} else {
				console.error("Error adding user:", response.statusText);
				generateToast(`Error adding user: ${response.statusText}`, "error"); // Show toast for user addition failure
			}
		} catch (error) {
			if (error.response && error.response.data) {
				// Extract and display error message if available
				const errorMessage = JSON.stringify(error.response.data, null, 2);
				generateToast(`Error adding user: ${errorMessage}`, "error");
			} else {
				generateToast(
					"An unknown error occurred while adding the user.",
					"error",
				);
			}
		}

		refresh(true);
	};

	return (
		<div
			className={`fixed inset-0 flex justify-center items-center transition-colors ${
				isOpen ? "visible bg-black/20" : "invisible"
			}`}
			onClick={closeModal}
		>
			<div
				className={`bg-white rounded-xl shadow p-6 transition-all ${
					isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
				}`}
				onClick={(e) => e.stopPropagation()}
				style={{ marginTop: "5vh" }}
			>
				<div className="form-container ml-6 mr-6 px-4 py-8">
					<h2 className="flex justify-content-start text-xl font-bold ml-8">
						Add User
					</h2>
					<button
						onClick={closeModal}
						className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
					>
						<X />
					</button>
					<form onSubmit={handleSubmit} className="bg-white rounded px-4 py-5">
						<div className="input-row w-full px-3 mb-10 mt-4 md:mb-0">
							<input
								type="text"
								id="email"
								name="email"
								placeholder="Email"
								value={formData.email}
								onChange={handleChange}
								className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							/>
						</div>
						<div className="flex flex-wrap">
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="firstName"
									name="first_name"
									placeholder="First Name"
									value={formData.first_name}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="lastName"
									name="last_name"
									placeholder="Last Name"
									value={formData.last_name}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="userName"
									name="username"
									placeholder="User Name"
									value={formData.username}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
							<div className="input-row w-full md:w-1/2 px-3 mb-10 mt-4 md:mb-0">
								<input
									type="text"
									id="password"
									name="password"
									placeholder="Password"
									value={formData.password}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
								/>
							</div>
						</div>
						<h1 className="mt-10 text-2xl text-left font-bold">Roles</h1>
						<h2 className="mt-4 text-2xl text-left">PlatForm Role</h2>
						<div className="input-row w-full md:w-2/3 px-3 mb-10 mt-4 md:mb-0">
							<select
								id="role"
								name="user_role"
								value={formData.user_role}
								onChange={handleChange}
								className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							>
								<option value="2">Admin</option>
								<option value="3">User</option>
							</select>
						</div>
						<div className="flex justify-between">
							<button
								type="submit"
								className="px-8 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddUser;
