import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Login from "./Pages/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthContext, AuthToken } from "./components/AuthToken";
import { ToastProvider } from "./Context/ToastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		{/* <BrowserRouter>
      <AuthContext.Provider value={new AuthToken()}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter> */}
		<ToastProvider>
			<App />
		</ToastProvider>
	</>,
);
