import React, { useState, useEffect, useRef } from "react";

const ViewCustomer = ({ isOpen, closeModal, data }) => {
	const [formData, setFormData] = useState({
		firstName: data.first_name,
		lastName: data.last_name,
		email: data.email,
		phoneNumber: data.phone_number,
		streetAddress: data.address,
		city: data.city,
		zipCode: data.zip_code,
		agent: data.agent,
		noOfPropertiesOwned: data.property_own,
		fileNumber: data.file_number,
		scenario: data.scenario,
		losLink: data.los_link,
		creditScore: data.credit_score,
		type: data.type,
		loanType: data.loan,
		rate: data.loan,
		miAmount: data.mi_amount,
		refiTargetRate: data.refi_target_rate,
		employmentType: data.employee_type,
		dateModified: data.date_modified,
		processor: data.processor, // added processor field
		buyer_agent: data.buyer_agent, // added buyerAgent field
		listing_agent: data.listing_agent, // added listingAgent field
		buyer_agent_2: data.buyer_agent2, // added buyerAgent2 field
		title_company: data.title_company, // added titleCompany field
		title_agent: data.title_company, // added titleAgent field
		lender_name: data.title_company, // added lenderName field
		insurance_agent: data.insurance_agent, // added insuranceAgent field
		closed_date: data.closed_date, // added closedDate field
		status: "Active",
	});

	const modalRef = useRef(null);
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	const handleChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log("Form Data:", formData);
	};
	const Openlink = (link) => {
		// Check if the link starts with 'http://' or 'https://'
		const validLink =
			link.startsWith("http://") || link.startsWith("https://")
				? link
				: `https://${link}`; // Add 'https://' if not present

		// Open the valid link in a new tab
		window.open(validLink, "_blank");
	};

	return (
		// <div
		// 	className={`fixed inset-0 flex justify-center items-center transition-colors ${
		// 		isOpen ? "visible bg-black/20" : "invisible"
		// 	}`}
		// 	onClick={closeModal}
		// >
		// 	<div
		// 		ref={modalRef}
		// 		className={`w-11/12 max-h-[90vh] bg-white rounded-xl shadow p-6 transition-all ${
		// 			isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
		// 		} overflow-y-auto`}
		// 		onClick={(e) => e.stopPropagation()}
		// 		style={{ marginTop: "18vh" }}
		// 	>
		// 		<div className="form-container">
		// 			<h2 className="flex justify-start text-xl font-bold ml-8">
		// 				View Record
		// 			</h2>
		// 			<div className="bg-white rounded px-4 py-5">
		// 				<div className="flex flex-wrap -mx-3 mb-6">
		// 					{[
		// 						{ id: "firstName", label: "First Name", type: "text" },
		// 						{ id: "lastName", label: "Last Name", type: "text" },
		// 						{ id: "email", label: "Email", type: "email" },
		// 						{ id: "phoneNumber", label: "Phone Number", type: "text" },
		// 						{ id: "streetAddress", label: "Street Address", type: "text" },
		// 						{ id: "city", label: "City", type: "text" },
		// 						{ id: "zipCode", label: "Zip Code", type: "text" },
		// 						{ id: "buyerAgent", label: "Buyer Agent", type: "text" },
		// 						{ id: "fileNumber", label: "File Number", type: "text" },
		// 					].map((field) => (
		// 						<div
		// 							key={field.id}
		// 							className="w-full md:w-1/3 px-3 mb-6 md:mb-0"
		// 						>
		// 							<label
		// 								className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 								htmlFor={field.id}
		// 							>
		// 								{field.label}
		// 							</label>
		// 							<p className="w-full px-3 py-2 text-gray-700 border-b border-gray-300">
		// 								{formData[field.id] || "-"}
		// 							</p>
		// 						</div>
		// 					))}
		// 					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
		// 						<label
		// 							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 							htmlFor="noOfPropertiesOwned"
		// 						>
		// 							Properties Owned
		// 						</label>
		// 						<p className="w-full px-3 py-2 text-gray-700 border-b border-gray-300">
		// 							{formData.noOfPropertiesOwned || "-"}
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="flex flex-wrap -mx-3 mb-6">
		// 					<div className="w-full px-3">
		// 						<label
		// 							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 							htmlFor="scenario"
		// 						>
		// 							Scenario
		// 						</label>
		// 						<p className="w-full px-3 py-2 text-gray-700 border-b border-gray-300">
		// 							{formData.scenario || "-"}
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="flex flex-wrap -mx-3 mb-6">
		// 					<div className="w-full px-3">
		// 						<label
		// 							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 							htmlFor="losLink"
		// 						>
		// 							LOS Link
		// 						</label>
		// 						<div className="flex">
		// 							<p className="flex-grow px-3 py-2 text-gray-700 border-b border-gray-300">
		// 								{formData.losLink || "-"}
		// 							</p>
		// 							{formData.losLink && (
		// 								<button
		// 									type="button"
		// 									onClick={() => Openlink(formData.losLink)}
		// 									className="bg-[#028ec8] hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md"
		// 								>
		// 									View Link
		// 								</button>
		// 							)}
		// 						</div>
		// 					</div>
		// 				</div>

		// 				{/* Additional fields */}
		// 				<div className="flex flex-wrap -mx-3 mb-6">
		// 					{[
		// 						{ id: "creditScore", label: "Credit Score", type: "text" },
		// 						{
		// 							id: "type",
		// 							label: "Type",
		// 							type: "select",
		// 							options: [
		// 								{ value: "FHA", label: "FHA" },
		// 								{ value: "USDA", label: "USDA" },
		// 								{ value: "CONVENTIONAL", label: "CONVENTIONAL" },
		// 								{ value: "VA", label: "VA" },
		// 								{ value: "DSCR", label: "DSCR" },
		// 								{ value: "HARD MONEY", label: "Hard Money" },
		// 								{ value: "Heloc", label: "HELOC" },
		// 								{ value: "Construction", label: "Construction" },
		// 								{ value: "Commercial", label: "Commercial" },
		// 								{ value: "Land", label: "Land" },
		// 							],
		// 						},
		// 						{ id: "rate", label: "Rate", type: "text" },
		// 						{ id: "miAmount", label: "Mi Amount", type: "text" },
		// 						{
		// 							id: "refiTargetRate",
		// 							label: "Refi Target Rate",
		// 							type: "text",
		// 						},
		// 						{
		// 							id: "employmentType",
		// 							label: "Employment Type",
		// 							type: "select",
		// 							options: [
		// 								{ value: "1", label: "Fixed Income" },
		// 								{ value: "2", label: "W2" },
		// 								{ value: "3", label: "Self-Employed" },
		// 							],
		// 						},
		// 						{ id: "dateModified", label: "Date Modified", type: "date" },
		// 					].map((field) => (
		// 						<div
		// 							key={field.id}
		// 							className="w-full md:w-1/3 px-3 mb-6 md:mb-0"
		// 						>
		// 							<label
		// 								className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 								htmlFor={field.id}
		// 							>
		// 								{field.label}
		// 							</label>
		// 							<p className="w-full px-3 py-2 text-gray-700 border-b border-gray-300">
		// 								{field.type === "select"
		// 									? field.options.find(
		// 											(opt) => opt.value === formData[field.id],
		// 										)?.label || "-"
		// 									: formData[field.id] || "-"}
		// 							</p>
		// 						</div>
		// 					))}
		// 				</div>

		// 				{/* Additional contact fields */}
		// 				<div className="flex flex-wrap -mx-3 mb-6">
		// 					{[
		// 						{ id: "processor", label: "Processor Name" },
		// 						{ id: "buyer_agent", label: "Agent Name" },
		// 						{ id: "listing_agent", label: "Listing Agent Name" },

		// 						{ id: "title_company", label: "Title Company" },
		// 						{ id: "title_agent", label: "Title Agent Name" },
		// 						{ id: "lender_name", label: "Lender Name" },
		// 						{ id: "insurance_agent", label: "Insurance Agent Name" },
		// 						{ id: "closed_date", label: "Closed Date", type: "date" },
		// 					].map((field) => (
		// 						<div
		// 							key={field.id}
		// 							className="w-full md:w-1/2 px-3 mb-6 md:mb-0"
		// 						>
		// 							<label
		// 								className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
		// 								htmlFor={field.id}
		// 							>
		// 								{field.label}
		// 							</label>
		// 							<p className="w-full px-3 py-2 text-gray-700 border-b border-gray-300">
		// 								{formData[field.id] || "-"}
		// 							</p>
		// 						</div>
		// 					))}
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<div
			className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 p-4"
			onClick={closeModal}
		>
			<div
				ref={modalRef}
				className="relative w-full max-w-4xl rounded-lg bg-white shadow-xl transition-all duration-300 ease-in-out"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex items-center justify-between border-b border-gray-200 p-4">
					<h2 className="text-2xl font-bold text-gray-800">View Record</h2>
					<button
						onClick={closeModal}
						className="rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
						aria-label="Close modal"
					>
						X
					</button>
				</div>

				<div className="max-h-[calc(100vh-10rem)] overflow-y-auto p-6">
					<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
						{[
							{ id: "firstName", label: "First Name" },
							{ id: "lastName", label: "Last Name" },
							{ id: "email", label: "Email" },
							{ id: "phoneNumber", label: "Phone Number" },
							{ id: "streetAddress", label: "Street Address" },
							{ id: "city", label: "City" },
							{ id: "zipCode", label: "Zip Code" },
							// { id: "buyerAgent", label: "Buyer Agent" },
							{ id: "fileNumber", label: "File Number" },
							{ id: "noOfPropertiesOwned", label: "Properties Owned" },
							{ id: "creditScore", label: "Credit Score" },
							// { id: "type", label: "Type" },
							{ id: "rate", label: "Rate" },
							{ id: "miAmount", label: "Mi Amount" },
							{ id: "refiTargetRate", label: "Refi Target Rate" },
							// { id: "employmentType", label: "Employment Type" },
							{ id: "dateModified", label: "Date Modified" },
							{ id: "processor", label: "Processor Name" },
							{ id: "buyer_agent", label: "Agent Name" },
							{ id: "listing_agent", label: "Listing Agent Name" },
							{ id: "title_company", label: "Title Company" },
							{ id: "title_agent", label: "Title Agent Name" },
							{ id: "lender_name", label: "Lender Name" },
							{ id: "insurance_agent", label: "Insurance Agent Name" },
							{ id: "closed_date", label: "Closed Date" },
						].map((field) => (
							<div key={field.id} className="space-y-1">
								<label className="text-xs font-medium uppercase text-gray-500">
									{field.label}
								</label>
								<p className="text-sm font-semibold text-gray-800">
									{formData[field.id] || "-"}
								</p>
							</div>
						))}
					</div>

					<div className="mt-6 space-y-1">
						<label className="text-xs font-medium uppercase text-gray-500">
							Scenario
						</label>
						<p className="text-sm text-gray-800">{formData.scenario || "-"}</p>
					</div>

					<div className="mt-6 space-y-1">
						<label className="text-xs font-medium uppercase text-gray-500">
							LOS Link
						</label>
						<div className="flex items-center space-x-4">
							<p className="flex-grow text-sm text-gray-800">
								{formData.losLink || "-"}
							</p>
							{formData.losLink && (
								<button
									type="button"
									onClick={() => Openlink(formData.losLink)}
									className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
								>
									View Link
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewCustomer;
