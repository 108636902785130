import React, { useCallback, useEffect, useState } from "react";

import Header from "./Header";
import axiosInstance from "../axiosInstance";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [customerData, setCustomerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCustomerList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        "https://djangobk.lenditcrm.com/customer/list/"
      );
      if (response.data.code === 200) {
        setCustomerData(response.data.data);
      } else {
        console.error("Error fetching customer list:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCustomerList();
  }, [fetchCustomerList]);

  return (
    <>
      <Header />
      <div className="flex flex-col bg-white m-4 rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-6">Dashboard Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2 bg-[#028EC8] rounded-xl p-6 text-white shadow-md">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold mb-2">Total Customers</h3>
                {isLoading ? (
                  <p className="text-3xl font-bold animate-pulse">Loading...</p>
                ) : (
                  <p className="text-4xl font-bold">{customerData?.length || 0}</p>
                )}
              </div>
              <FaUsers size={48} />
            </div>
            
          </div>
          <div className="bg-gray-100 rounded-xl p-6 shadow-md">
            <h3 className="text-lg font-semibold mb-4">Quick Actions</h3>
            <ul className="space-y-2">
              <li>
				<Link to={"/addcustomer"}>
                <button className="w-full text-left py-2 px-4 rounded bg-white hover:bg-gray-200 transition-colors">
                  Add New Customer
                </button>
				</Link>
              </li>
              <li>
			  <Link to={"/activefiles"}>
                <button className="w-full text-left py-2 px-4 rounded bg-white hover:bg-gray-200 transition-colors">
                  Active Files
                </button>
				</Link>
              </li>
              <li>
			  <Link to={"/reports"}>
                <button className="w-full text-left py-2 px-4 rounded bg-white hover:bg-gray-200 transition-colors">
                  View Reports
                </button>
				</Link>
              </li>
			  
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;