// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

const NotFound = () => {
	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
			<h1 className="text-4xl font-bold text-gray-700">404</h1>
			<p className="text-xl text-gray-500">Page Not Found</p>
			<Link to="/listing" className="mt-4 text-blue-500 hover:underline">
				Go Back to Home
			</Link>
		</div>
	);
};

export default NotFound;
