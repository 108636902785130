import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../axiosInstance";
import { useAuth } from "../../components/AuthToken";
import { useToast } from "../../Context/ToastContext";

const EditCustomer = ({ isOpen, closeModal, data, id, refresh }) => {
	const { generateToast } = useToast();

	const [formData, setFormData] = useState({
		firstName: data?.first_name,
		lastName: data?.last_name,
		phoneNumber: data.phone_number,
		streetAddress: data.address,
		city: data.city,
		zipCode: data.zip_code,
		agent: data.agent,
		noOfPropertiesOwned: data.property_own,
		fileNumber: data.file_number,
		scenario: data.scenario,
		losLink: data.los_link,
		creditScore: data.credit_score,
		type: data.type,
		loanType: data.loan,
		rate: data.loan,
		miAmount: data.mi_amount,
		refiTargetRate: data.refi_target_rate,
		employmentType: data.employee_type,
		dateModified: data.date_modified,
		processor: data.processor, // added processor field
		buyer_agent: data.buyer_agent, // added buyerAgent field
		listing_agent: data.listing_agent, // added listingAgent field
		// buyer_agent_2: data.buyer_agent2, // added buyerAgent2 field
		title_company: data.title_company, // added titleCompany field
		title_agent: data.title_agent, // added titleAgent field
		lender_name: data.lender_name, // added lenderName field
		insurance_agent: data.insurance_agent, // added insuranceAgent field
		closed_date: data.closed_date, // added closedDate field
	});

	const modalRef = useRef(null);
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	const handleChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const filterKeys = (formData) => {
		// const filteredData = {
		//     first_name: formData.firstName,
		//     last_name: formData.lastName,
		//     phone_number: formData.phoneNumber,
		//     address: formData.streetAddress,
		//     city: formData.city,
		//     zip_code: formData.zipCode,
		//     agent: parseInt(formData.agent),
		//     property_own: parseInt(formData.noOfPropertiesOwned),
		//     file_number: formData.fileNumber,
		//     scenario: formData.scenario,
		//     los_link: formData.losLink,
		//     credit_score: parseInt(formData.creditScore),
		//     type: formData.type,
		//     loan: parseInt(formData.loanType),
		//     rate: formData.rate,
		//     mi_amount: formData.miAmount,
		//     refi_target_rate: formData.refiTargetRate,
		//     date_modified: formData.dateModified,
		//     employee_type: parseInt(formData.employmentType),
		// };
		const filteredData = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			phone_number: formData.phoneNumber,
			email: formData.email, // added email field
			address: formData.streetAddress,
			city: formData.city,
			zip_code: formData.zipCode,
			property_own: 1,
			file_number: formData.fileNumber,
			scenario: formData.scenario,
			los_link: formData.losLink,
			credit_score: parseInt(formData.creditScore),
			type: formData.type,
			loan: parseInt(formData.loanType),
			rate: formData.rate,
			mi_amount: formData.miAmount,
			refi_target_rate: formData.refiTargetRate,
			date_modified: formData.dateModified,
			employee_type: parseInt(formData.employmentType),
			processor: formData.processor, // added processor field
			buyer_agent: formData.buyer_agent, // added buyerAgent field
			listing_agent: formData.listing_agent, // added listingAgent field
			// buyer_agent2: formData.buyer_agent2, // added buyerAgent2 field
			title_company: formData.title_company, // added titleCompany field
			title_agent: formData.title_agent, // added titleAgent field
			lender_name: formData.lender_name, // added lenderName field
			insurance_agent: formData.insurance_agent, // added insuranceAgent field
			closed_date: formData.closed_date, // added closedDate field
		};
		return filteredData;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const accessToken = localStorage.getItem("accessToken");
			if (!accessToken) {
				console.error("Access token not found in localStorage");
				return;
			}

			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			};
			const filteredData = filterKeys(formData);

			const response = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/customer/update/",
				{
					id: id,
					...filteredData,
				},
				{
					headers: headers,
				},
			);

			// Check response status and API code inside the response data
			if (response.status === 200 && response.data.code === 200) {
				generateToast("Customer updated Successfully", "success");
				console.log("Customer updated successfully!");
			} else {
				generateToast("Failed to update customer", "error");
				throw new Error("Failed to update customer");
			}
		} catch (error) {
			generateToast(`Error Updating: ${error.message}`, "error");
			console.error("Error:", error);
			if (error.response && error.response.status === 401) {
				// Handle unauthorized case
				// window.location.href = '/';
			}
		}
		refresh(true);
		closeModal();
	};

	return (
		// <div
		// 	className={`fixed inset-0 flex justify-center items-center transition-colors ${
		// 		isOpen ? "visible bg-black/20" : "invisible"
		// 	}`}
		// 	onClick={closeModal}
		// >
		// 	<div
		// 		ref={modalRef}
		// 		className={`w-11/12 bg-white rounded-xl shadow p-6 transition-all ${
		// 			isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
		// 		} overflow-y-auto`}
		// 		onClick={(e) => e.stopPropagation()}
		// 		style={{ marginTop: "10vh", maxHeight: "80vh" }} // Adjusted to ensure it takes 80% of screen height
		// 	>
		// 		<div className="form-container">
		// 			<h2 className="flex justify-content-start text-xl font-bold ml-8">
		// 				Update Record
		// 			</h2>
		// 			<form onSubmit={handleSubmit} className="bg-white rounded px-4 py-5">
		// 				<div className="flex flex-wrap">
		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="firstName"
		// 							name="firstName"
		// 							placeholder="Ali"
		// 							value={formData.firstName}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>
		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="lastName"
		// 							name="lastName"
		// 							placeholder="Bilal"
		// 							value={formData.lastName}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="phoneNumber"
		// 							name="phoneNumber"
		// 							placeholder="123456789"
		// 							value={formData.phoneNumber}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="streetAddress"
		// 							name="streetAddress"
		// 							placeholder="House 123, lane 01"
		// 							value={formData.streetAddress}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="city"
		// 							name="city"
		// 							placeholder="Rawalpindi"
		// 							value={formData.city}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="zipCode"
		// 							name="zipCode"
		// 							placeholder="1234"
		// 							value={formData.zipCode}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="agent"
		// 							name="agent"
		// 							placeholder="Shazhad"
		// 							value={formData.agent}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="">Shazhad</option>
		// 							<option value="agent1">Agent 1</option>
		// 							<option value="agent2">Agent 2</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="noOfPropertiesOwned"
		// 							name="noOfPropertiesOwned"
		// 							placeholder="Bahria Town"
		// 							value={formData.noOfPropertiesOwned}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="1">Properties 1</option>
		// 							<option value="2">Properties 2</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="fileNumber"
		// 							name="fileNumber"
		// 							placeholder="123456"
		// 							value={formData.fileNumber}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="agent1">FileNumber</option>
		// 							<option value="agent1">fileNumber 1</option>
		// 							<option value="agent2">fileNumber 2</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<label
		// 							className="flex justify-content-start w-full md:w-1/3 px-3 mb-6 md:mb-0"
		// 							htmlFor="scenario"
		// 						>
		// 							Scenario:
		// 						</label>
		// 						<textarea
		// 							type="text"
		// 							id="scenario"
		// 							name="scenario"
		// 							placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
		//                                     Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
		//                                     scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
		//                                     remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
		//                                     and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
		// 							rows={4}
		// 							cols={50}
		// 							value={formData.scenario}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<label
		// 							className="flex justify-content-start w-full md:w-1/3 px-3 mb-6 md:mb-0"
		// 							htmlFor="losLink"
		// 						>
		// 							Enter Los Link:
		// 						</label>
		// 						<input
		// 							type="text"
		// 							id="losLink"
		// 							name="losLink"
		// 							placeholder="https://example.com"
		// 							value={formData.losLink}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 						<button className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-1 px-4 rounded-lg mt-2">
		// 							Lead Source
		// 						</button>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-10 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="creditScore"
		// 							name="creditScore"
		// 							placeholder="100"
		// 							value={formData.creditScore}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="type"
		// 							name="type"
		// 							placeholder="Type"
		// 							value={formData.type}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="any">Any</option>
		// 							<option value="agent2">Type 2</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="loanType"
		// 							name="loanType"
		// 							placeholder="Loan Type"
		// 							value={formData.loanType}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="1">FHA</option>
		// 							<option value="2">USDA</option>
		// 							<option value="3">Conventional</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="rate"
		// 							name="rate"
		// 							placeholder="6.5%"
		// 							value={formData.rate}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="miAmount"
		// 							name="miAmount"
		// 							placeholder="ML2000"
		// 							value={formData.miAmount}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="refiTargetRate"
		// 							name="refiTargetRate"
		// 							placeholder="6.5% or 8.5%"
		// 							value={formData.refiTargetRate}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<select
		// 							id="employmentType"
		// 							name="employmentType"
		// 							placeholder="Employment Type"
		// 							value={formData.employmentType}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						>
		// 							<option value="1">Fixed Income</option>
		// 							<option value="1">w2</option>
		// 							<option value="3">Self-Employed</option>
		// 						</select>
		// 					</div>

		// 					<div className="input-row w-full md:w-1/3 px-3 mb-10 mt-4 md:mb-0">
		// 						<input
		// 							type="text"
		// 							id="dateModified"
		// 							name="dateModified"
		// 							placeholder="January/21/2024"
		// 							value={formData.dateModified}
		// 							onChange={handleChange}
		// 							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
		// 						/>
		// 					</div>

		// 					<button
		// 						className="mt-10 ml-auto text-base bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-1 px-4 rounded-lg"
		// 						type="submit"
		// 					>
		// 						Save
		// 					</button>
		// 				</div>
		// 			</form>
		// 		</div>
		// 	</div>
		// </div>

		<div
			className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 p-4"
			onClick={closeModal}
		>
			<div
				ref={modalRef}
				className="relative w-full max-w-4xl rounded-lg bg-white shadow-xl transition-all duration-300 ease-in-out"
				onClick={(e) => e.stopPropagation()}
				style={{ marginTop: "10vh", maxHeight: "80vh" }}
			>
				<div className="flex items-center justify-between border-b border-gray-200 p-4">
					<h2 className="text-2xl font-bold text-gray-800">Update Record</h2>
					<button
						onClick={closeModal}
						className="rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
						aria-label="Close modal"
					>
						X
					</button>
				</div>

				<div className="max-h-[calc(80vh-4rem)] overflow-y-auto p-6">
					<form onSubmit={handleSubmit} className="space-y-6">
						<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
							{[
								{ id: "firstName", label: "First Name", placeholder: "Ali" },
								{ id: "lastName", label: "Last Name", placeholder: "Bilal" },
								{
									id: "phoneNumber",
									label: "Phone Number",
									placeholder: "123456789",
								},
								{
									id: "email",
									label: "Email",
									placeholder: "example@example.com", // New email field
								},
								{
									id: "streetAddress",
									label: "Street Address",
									placeholder: "House 123, lane 01",
								},
								{ id: "city", label: "City", placeholder: "Rawalpindi" },
								{ id: "zipCode", label: "Zip Code", placeholder: "1234" },
								{
									id: "noOfPropertiesOwned",
									label: "Properties Owned",
									type: "select",
									options: [
										{ value: "1", label: "Properties 1" },
										{ value: "2", label: "Properties 2" },
									],
								},
								{ id: "fileNumber", label: "File Number", placeholder: "1234" },
								{
									id: "creditScore",
									label: "Credit Score",
									placeholder: "100",
								},
								{
									id: "type",
									label: "Type",
									type: "select",
									options: [
										{ value: "any", label: "Any" },
										{ value: "agent2", label: "Type 2" },
									],
								},
								{
									id: "loanType",
									label: "Loan Type",
									type: "select",
									options: [
										{ value: "1", label: "FHA" },
										{ value: "2", label: "USDA" },
										{ value: "3", label: "Conventional" },
									],
								},
								{ id: "rate", label: "Rate", placeholder: "6.5%" },
								{ id: "miAmount", label: "MI Amount", placeholder: "ML2000" },
								{
									id: "refiTargetRate",
									label: "Refi Target Rate",
									placeholder: "6.5% or 8.5%",
								},
								{
									id: "employmentType",
									label: "Employment Type",
									type: "select",
									options: [
										{ value: "1", label: "Fixed Income" },
										{ value: "2", label: "W2" },
										{ value: "3", label: "Self-Employed" },
									],
								},
								{
									id: "dateModified",
									label: "Date Modified",
									placeholder: "January/21/2024",
								},
								{
									id: "processor",
									label: "Processor",
									placeholder: "Processor name",
								},
								{
									id: "buyer_agent",
									label: "Buyer Agent",
									placeholder: "Buyer agent name",
								},
								{
									id: "listing_agent",
									label: "Listing Agent",
									placeholder: "Listing agent name",
								},

								{
									id: "title_company",
									label: "Title Company",
									placeholder: "Title company name",
								},
								{
									id: "title_agent",
									label: "Title Agent",
									placeholder: "Title agent name",
								},
								{
									id: "lender_name",
									label: "Lender Name",
									placeholder: "Lender name",
								},
								{
									id: "insurance_agent",
									label: "Insurance Agent",
									placeholder: "Insurance agent name",
								},
								{ id: "closed_date", label: "Closed Date", type: "date" },
							].map((field) => (
								<div key={field.id} className="space-y-1">
									<label
										htmlFor={field.id}
										className="block text-sm font-medium text-gray-700"
									>
										{field.label}
									</label>
									{field.type === "select" ? (
										<select
											id={field.id}
											name={field.id}
											value={formData[field.id]}
											onChange={handleChange}
											className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
										>
											{field.options.map((option) => (
												<option key={option.value} value={option.value}>
													{option.label}
												</option>
											))}
										</select>
									) : (
										<input
											type={field.type || "text"}
											id={field.id}
											name={field.id}
											placeholder={field.placeholder}
											value={formData[field.id]}
											onChange={handleChange}
											className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
										/>
									)}
								</div>
							))}
						</div>

						<div className="space-y-2">
							<label
								htmlFor="scenario"
								className="block text-sm font-medium text-gray-700"
							>
								Scenario:
							</label>
							<textarea
								id="scenario"
								name="scenario"
								rows={4}
								placeholder="Enter scenario details..."
								value={formData.scenario}
								onChange={handleChange}
								className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
							/>
						</div>

						<div className="space-y-2">
							<label
								htmlFor="losLink"
								className="block text-sm font-medium text-gray-700"
							>
								Enter Los Link:
							</label>
							<div className="flex items-center space-x-2">
								<input
									type="text"
									id="losLink"
									name="losLink"
									placeholder="https://example.com"
									value={formData.losLink}
									onChange={handleChange}
									className="flex-grow rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
								/>
								<button
									type="button"
									className="rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									Lead Source
								</button>
							</div>
						</div>

						<div className="flex justify-end">
							<button
								type="submit"
								className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
							>
								Save
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditCustomer;
