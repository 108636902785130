import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { useToast } from "../../../Context/ToastContext";

export default function NotesApp() {
	const [noteData, setNoteData] = useState({ title: "", description: "" });
	const { generateToast } = useToast();
	const [notes, setNotes] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		fetchDataNotes();
	}, [refresh]);

	const fetchDataNotes = async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/customer/note_list/",
			);
			setNotes(response.data.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleAddNote = async (event) => {
		event.preventDefault();

		const { title, description } = noteData;
		const payload = {
			title: title,
			note: description,
		};

		try {
			const response = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/customer/note_add/",
				payload,
			);
			console.log("Note added successfully:", response.data);
			generateToast("Notes Added Successfully:", "success");
			setRefresh(!refresh);
			setNoteData({ title: "", description: "" }); // Reset fields after adding
		} catch (error) {
			console.error("Error adding note:", error);
			generateToast(`Error adding note:${error}`, "error");
		}
	};

	const handleDeleteNote = async (id) => {
		const url = `https://djangobk.lenditcrm.com/customer/note_delete/?id=${id}`;
		try {
			await axiosInstance.get(url);
			generateToast("Notes deleted Successfully", "success");
			setRefresh(true);
		} catch (error) {
			console.error("Error deleting note:", error);
			generateToast(`Error deleting note:${error}`, "error");
		}
	};

	const filteredNotes = notes.filter(
		(note) => note.title.toLowerCase().includes(searchTerm.toLowerCase()),
		// ||note.note.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div
			style={{
				width: "400px",
				border: "1px solid #e2e8f0",
				borderRadius: "8px",
				overflow: "hidden",
			}}
		>
			<div
				style={{
					backgroundColor: "#0099cc",
					color: "white",
					padding: "16px",
					fontSize: "20px",
				}}
			>
				Add Notes
			</div>
			<div style={{ padding: "16px" }}>
				<label style={{ display: "block", marginBottom: "8px" }}>
					Note Title
				</label>
				<input
					type="text"
					value={noteData.title}
					onChange={(e) => setNoteData({ ...noteData, title: e.target.value })}
					placeholder="Enter note title"
					style={{
						width: "100%",
						padding: "8px",
						border: "1px solid #e2e8f0",
						borderRadius: "4px",
						marginBottom: "16px",
					}}
				/>
				<label style={{ display: "block", marginBottom: "8px" }}>
					Note Description
				</label>
				<textarea
					value={noteData.description}
					onChange={(e) =>
						setNoteData({ ...noteData, description: e.target.value })
					}
					placeholder="Enter note description"
					style={{
						width: "100%",
						padding: "8px",
						border: "1px solid #e2e8f0",
						borderRadius: "4px",
						marginBottom: "16px",
						minHeight: "100px",
					}}
				/>
				<div
					style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
				>
					<button
						onClick={() => setNoteData({ title: "", description: "" })}
						style={{
							backgroundColor: "#ef4444",
							color: "white",
							border: "none",
							padding: "8px 16px",
							borderRadius: "4px",
							cursor: "pointer",
						}}
					>
						Discard
					</button>
					<button
						onClick={handleAddNote}
						style={{
							backgroundColor: "#0099cc",
							color: "white",
							border: "none",
							padding: "8px 16px",
							borderRadius: "4px",
							cursor: "pointer",
						}}
					>
						Add
					</button>
				</div>
			</div>
			<div style={{ padding: "16px", borderTop: "1px solid #e2e8f0" }}>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					placeholder="Search here.."
					style={{
						width: "100%",
						padding: "8px",
						border: "1px solid #e2e8f0",
						borderRadius: "4px",
						marginBottom: "16px",
					}}
				/>
				{filteredNotes.slice(0, 5).map((note) => (
					<div
						key={note.id}
						style={{
							backgroundColor: "#f8fafc",
							padding: "16px",
							marginBottom: "16px",
							borderRadius: "4px",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: "8px",
							}}
						>
							<h3 style={{ margin: 0, fontSize: "18px", fontWeight: 600 }}>
								{note.title}
							</h3>
							<button
								onClick={() => handleDeleteNote(note.id)}
								style={{
									background: "none",
									border: "none",
									cursor: "pointer",
								}}
							>
								🗑️
							</button>
						</div>
						<p style={{ margin: "0 0 8px 0", color: "#64748b" }}>
							{new Date(note.created_at).toLocaleDateString("en-GB")}
						</p>
						<p style={{ margin: 0 }}>{note.note}</p>
					</div>
				))}
			</div>
		</div>
	);
}
