import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../Context/ToastContext";
const schema = z
	.object({
		firstName: z.string().min(1, "First name is required"),
		lastName: z.string().min(1, "Last name is required"),
		company: z.string().min(1, "Company name is required"),
		workEmail: z.string().email("Invalid email address"),
		employees: z.enum(["0<20", "20-50", "50-100", "100<"]),
		phoneNumber: z.string().regex(/^\+?[0-9\s]+$/, "Invalid phone number"),
		password: z.string().min(6, "Password must be at least 6 characters"),
		confirmPassword: z.string(),
		termsAgreed: z
			.boolean()
			.refine(
				(val) => val === true,
				"You must agree to the terms and conditions",
			),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ["confirmPassword"],
	});

function Register() {
	const { generateToast } = useToast();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
	});

	// onSubmit function
	const onSubmit = async (data) => {
		// Validate the data using the schema
		try {
			schema.parse(data); // This will throw an error if validation fails
		} catch (error) {
			console.error("Validation error:", error.errors);
			return; // Stop execution if validation fails
		}

		// Map data to API format if needed
		const apiData = {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.workEmail,
			password: data.password,
			confirm_password: data.password,
			user_role: "3",
			company: data.company,
			terms_agreed: data.termsAgreed,
			username: data.firstName + data.lastName,
		};

		// Make the API request
		try {
			const response = await axios.post(
				"https://djangobk.lenditcrm.com/user/register/",
				apiData,
			);
			console.log("Registration successful:", response.data);
			generateToast("Registration succssuful", "success");
			navigate("/");

			// Handle successful registration (e.g., redirect to login page, show success message)
		} catch (error) {
			console.error(
				"API error:",
				error.response ? error.response.data : error.message,
			);
			generateToast(`Error adding ${error.response.data}`, "error");
			// Handle API errors (e.g., show error message to user)
		}
	};
	return (
		<div className="flex w-full min-h-screen bg-gray-100">
			{/* Left side */}
			<div className="w-1/3 bg-blue-500 flex items-center justify-center">
				<div className="text-white text-center">
					<h1 className="text-6xl font-bold">LENDIT CRM</h1>
					<p className="mt-4 text-xl">POWERED BY MAXARON TECHNOLOGIES</p>
				</div>
			</div>

			{/* Right side */}
			<div className="w-2/3 p-8 overflow-y-auto">
				<div className="grid grid-cols-2 gap-4">
					<div>
						<h2 className="text-5xl font-bold mb-2">Input your information</h2>
					</div>
					<div>
						<p className="text-md text-justify text-gray-600">
							We need you to help us with some basic information for your
							account creation. Here are our{" "}
							<a href="#" className="text-blue-500 hover:underline">
								terms and conditions
							</a>
							. Please read them carefully.
						</p>
					</div>
				</div>
				<div className="mt-10">
					<form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
						<div className="grid grid-cols-2 gap-6">
							<div>
								<label
									htmlFor="firstName"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									First name
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("firstName")}
									type="text"
									id="firstName"
									className="mt-1 block w-full h-[80%] rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
								/>
								{errors.firstName && (
									<p className="mt-1 text-sm text-red-600">
										{errors.firstName.message}
									</p>
								)}
							</div>
							<div>
								<label
									htmlFor="lastName"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									Last name
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("lastName")}
									type="text"
									id="lastName"
									className="mt-1 block w-full h-[80%]  rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
								/>
								{errors.lastName && (
									<p className="mt-1 text-sm text-red-600">
										{errors.lastName.message}
									</p>
								)}
							</div>
						</div>
						<p className="text-sm text-gray-600 mt-2">
							Your username will be a combination of your first name and last
							name.
						</p>

						<div className="grid grid-cols-2 gap-6">
							<div>
								<label
									htmlFor="company"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									Company
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("company")}
									type="text"
									id="company"
									className="mt-1 block w-full h-[80%]  rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
								/>
								{errors.company && (
									<p className="mt-1 text-sm text-red-600">
										{errors.company.message}
									</p>
								)}
							</div>
							<div>
								<label
									htmlFor="workEmail"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									Work email
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("workEmail")}
									type="email"
									id="workEmail"
									className="mt-1 block w-full rounded-md h-[80%]  border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
								/>
								{errors.workEmail && (
									<p className="mt-1 text-sm text-red-600">
										{errors.workEmail.message}
									</p>
								)}
							</div>
						</div>

						<div className="grid grid-cols-2 gap-6 mt-8">
							<div className="">
								<label className="block text-sm text-left font-medium text-gray-700 mb-2">
									Number of employees
								</label>
								<div className="mt-2 flex space-x-4">
									{["0<20", "20-50", "50-100", "100<"].map((option) => (
										<label
											key={option}
											className={`inline-flex items-center px-4 py-2 border rounded-lg cursor-pointer transition ${
												watch("employees") === option
													? "bg-blue-50 border-blue-500 text-blue-600"
													: "border-gray-300"
											}`}
										>
											<input
												{...register("employees")}
												type="radio"
												value={option}
												className="sr-only"
											/>
											<span>{option}</span>
										</label>
									))}
								</div>
								{errors.employees && (
									<p className="mt-1 text-sm text-red-600">
										{errors.employees.message}
									</p>
								)}
							</div>
							<div>
								<label
									htmlFor="phoneNumber"
									className="block text-sm font-medium text-gray-700 flex justify-between mb-2"
								>
									Phone number
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("phoneNumber")}
									type="tel"
									id="phoneNumber"
									className="mt-1 block w-full h-[50%]  rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
								/>
								{errors.phoneNumber && (
									<p className="mt-1 text-sm text-red-600">
										{errors.phoneNumber.message}
									</p>
								)}
							</div>
						</div>

						<div className="grid grid-cols-2 gap-6">
							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									Password
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("password")}
									type="password"
									id="password"
									className="mt-1 block w-full h-[80%] rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
									required
								/>
								{errors.password && (
									<p className="mt-1 text-sm text-red-600">
										{errors.password.message}
									</p>
								)}
							</div>
							<div>
								<label
									htmlFor="confirmPassword"
									className="block text-sm font-medium text-gray-700 flex justify-between"
								>
									Confirm Password
									<span className="text-gray-400">?</span>
								</label>
								<input
									{...register("confirmPassword")}
									type="password"
									id="confirmPassword"
									className="mt-1 block w-full h-[80%] rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
									required
								/>
								{errors.confirmPassword && (
									<p className="mt-1 text-sm text-red-600">
										{errors.confirmPassword.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex items-center">
							<input
								{...register("termsAgreed")}
								type="checkbox"
								id="termsAgreed"
								className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
							/>
							<label
								htmlFor="termsAgreed"
								className="ml-2 block text-sm text-gray-900"
							>
								I agree with{" "}
								<a href="#" className="text-blue-500 hover:underline">
									terms and conditions
								</a>
								.
							</label>
						</div>
						{errors.termsAgreed && (
							<p className="mt-1 text-sm text-red-600">
								{errors.termsAgreed.message}
							</p>
						)}

						<div>
							<button
								type="submit"
								className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Register
							</button>
						</div>
					</form>
				</div>

				<p className="mt-4 text-center text-sm text-gray-600">
					Already Registered?{" "}
					<a href="/" className="font-medium text-blue-600 hover:text-blue-500">
						Sign in
					</a>
				</p>
			</div>
		</div>
	);
}

export default Register;
