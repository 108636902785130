import React, { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaEnvelope, FaTrash, FaUserEdit, FaUserPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import axiosInstance from "../../axiosInstance";
import EmailModal from "../components/EmailModal";
// Import your components
import Header from "../Header";
import Delete from "./Delete";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { useToast } from "../../Context/ToastContext";
import { useAuth } from "../../components/AuthToken";
import NotFound from "../NotFound";
import sendBulkEmail from "../components/sendBulkEmail";
const User = () => {
	const { generateToast } = useToast();
	const [rowData, setRowData] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const { userId } = useAuth();
	const [modalState, setModalState] = useState({
		isDeleteModalOpen: false,
		isAddUserModalOpen: false,
		isEditUserModalOpen: false,
		selectedUser: null,
	});

	const fetchUserList = useCallback(async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/user/get_users/",
			);
			if (response.data.code === 200) {
				setRowData(response.data.data);
			} else {
				console.error("Error fetching user list:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	useEffect(() => {
		fetchUserList();
	}, [fetchUserList]);

	const handleDelete = async (ids) => {
		try {
			await Promise.all(
				ids.map((id) =>
					axiosInstance.get(
						`https://djangobk.lenditcrm.com/user/delete/?id=${id}`,
					),
				),
			);
			generateToast("Users Deleted Succesfully", "success");
			fetchUserList();
			setSelectedRows([]);
		} catch (error) {
			console.error("Error deleting users:", error);
		}
	};
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const handleMassEmail = () => {
		const emails = selectedRows.map((row) => row.email).filter((email) => email !== null && email !== undefined);
		
		if (emails.length === 0) {
			generateToast("No valid emails selected. Please select recipients.", "error");
			return;
		}
		setSelectedEmails(emails);
		setModalOpen(true);
		// Implement your mass email logic here
	};
	const handleCloseModal = () => setModalOpen(false);

	const handleSendEmail = async (emails, subject, body) => {
		await sendBulkEmail(emails, subject, body);
		setModalOpen(false);
	  };
	  

	const ActionsRenderer = ({ data }) => (
		<div className="flex mt-1 text-3xl">
			<FaUserEdit
				className="text-blue-500 hover:text-blue-700 cursor-pointer mr-2"
				onClick={() =>
					setModalState({ isEditUserModalOpen: true, selectedUser: data })
				}
			/>
			<MdDelete
				className="text-red-500 hover:text-red-700 cursor-pointer"
				onClick={() =>
					setModalState({ isDeleteModalOpen: true, selectedUser: data })
				}
			/>
		</div>
	);

	const columnDefs = [
		{ headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
		{ headerName: "ID", field: "id", filter: true },
		{
			headerName: "USERNAME",
			field: "username",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "FULL NAME",
			valueGetter: (params) =>
				`${params.data.first_name} ${params.data.last_name}`,
			filter: true,
			// floatingFilter: true,
		},
		{ headerName: "EMAIL", field: "email", filter: true },
		{
			headerName: "STATUS",
			field: "status",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "DATE/TIME",
			field: "created",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "ROLE",
			field: "user_role",
			filter: true,
			// floatingFilter: true,
		},
		{ headerName: "ACTIONS", cellRenderer: ActionsRenderer, filter: false },
	];

	const onSelectionChanged = (event) => {
		const selectedNodes = event.api.getSelectedNodes();
		setSelectedRows(selectedNodes.map((node) => node.data));
	};

	if (userId !== "1") {
		return <NotFound />;
	}

	return (
		<>
			<Header />
			<div className="mx-2">
				<div className="font-bold text-3xl flex items-start ml-10 mt-10 mb-10">
					User Management
				</div>
				<div className="ag-theme-alpine mt-2 ml-6 mr-6 border border-grey-300 rounded-lg p-4">
					<div className="flex justify-between items-center mb-4">
						<div>
							<button
								onClick={() => handleDelete(selectedRows.map((row) => row.id))}
								className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
								disabled={selectedRows.length === 0}
							>
								<FaTrash className="inline-block mr-1" />
								Delete Selected
							</button>
							<button
								onClick={handleMassEmail}
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
								disabled={selectedRows.length === 0}
							>
								<FaEnvelope className="inline-block mr-1" />
								Send Mass Email
							</button>
						</div>
						<button
							onClick={() => setModalState({ isAddUserModalOpen: true })}
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
						>
							<FaUserPlus className="mr-2" />
							Add User
						</button>
					</div>
					<div style={{ height: "calc(100vh - 280px)", width: "100%" }}>
						<AgGridReact
							columnDefs={columnDefs}
							rowData={rowData}
							rowSelection="multiple"
							onSelectionChanged={onSelectionChanged}
							suppressRowClickSelection={true}
						/>
					</div>
				</div>
			</div>
			<EmailModal
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			onSendEmail={handleSendEmail}
			selectedEmails={selectedEmails}
			/>

			{modalState.isDeleteModalOpen && (
				<Delete
					id={
						modalState.selectedUser
							? modalState.selectedUser.id
							: selectedRows.map((row) => row.id)
					}
					name="user"
					isOpen={modalState.isDeleteModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isDeleteModalOpen: false }))
					}
					refresh={fetchUserList}
				/>
			)}
			{modalState.isAddUserModalOpen && (
				<AddUser
					isOpen={modalState.isAddUserModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isAddUserModalOpen: false }))
					}
					refresh={fetchUserList}
				/>
			)}
			{modalState.isEditUserModalOpen && (
				<EditUser
					userId={modalState.selectedUser.id}
					data={modalState.selectedUser}
					isOpen={modalState.isEditUserModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isEditUserModalOpen: false }))
					}
					refresh={fetchUserList}
				/>
			)}
		</>
	);
};

export default User;
