import React, { useState, useEffect } from "react";
import Header from "../Header";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import NotesApp from "./components/AddNotes";
import { useToast } from "../../Context/ToastContext";
import { XMLParser } from "fast-xml-parser";
import Papa from "papaparse";
import { FaCaretDown } from "react-icons/fa";

const InputField = ({ type, id, name, placeholder, value, handleChange }) => (
	<input
		type={type}
		id={id}
		name={name}
		placeholder={placeholder}
		value={value}
		onChange={handleChange}
		className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
	/>
);
const initialFormData = {
	firstName: "",
	lastName: "",
	phoneNumber: "",
	streetAddress: "",
	city: "",
	email: "",
	zipCode: "",
	agent: "",
	noOfPropertiesOwned: "",
	fileNumber: "",
	scenario: "",
	losLink: "",
	leadSource: "",
	creditScore: "",
	type: "",
	loanType: "",
	rate: "",
	miAmount: "",
	refiTargetRate: "",
	employmentType: "",
	dateModified: "",
	processor: "",
	buyerAgent: "",
	listingAgent: "",
	titleCompany: "",
	titleAgent: "",
	lenderName: "",
	insuranceAgent: "",
	closedDate: "",
	state: "",
};

const initialNoteData = {
	title: "",
	description: "",
};

const AddCustomer = () => {
	const [refresh, setRefresh] = useState(false);

	const { generateToast } = useToast();
	const [notesdatalist, setNotesDataList] = useState([]);
	const nav = useNavigate();
	const [showXmlUpload, setShowXmlUpload] = useState(false);
	const [xmlFile, setXmlFile] = useState(null);
	const [customers, setCustomers] = useState([]);
	const [parsedCustomers, setParsedCustomers] = useState([]);
	const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [step, setStep] = useState(1);
	const [file, setFile] = useState(null);
	const [headers, setHeaders] = useState([]);
	const [mapping, setMapping] = useState({});
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	// Handle file selection
	const handleFileChange = (event) => {
		setXmlFile(event.target.files[0]);
		setParsedCustomers([]);
		setSelectedCustomerIndex(0);
	};

	// const handleXmlUpload = async (event) => {
	// 	event.preventDefault();
	// 	if (!xmlFile) {
	// 		generateToast("Please Select an XML File", "info");
	// 		return;
	// 	}

	// 	const reader = new FileReader();
	// 	reader.onload = async (e) => {
	// 		const xmlContent = e.target.result;
	// 		try {
	// 			const parser = new XMLParser({
	// 				ignoreAttributes: false,
	// 				attributeNamePrefix: "",
	// 				ignoreNameSpace: true,
	// 				allowBooleanAttributes: true,
	// 			});
	// 			const result = parser.parse(xmlContent);

	// 			let customersData = [];

	// 			if (result.customer) {
	// 				customersData = [result.customer];
	// 			} else if (result.customers && result.customers.customer) {
	// 				customersData = Array.isArray(result.customers.customer)
	// 					? result.customers.customer
	// 					: [result.customers.customer];
	// 			}

	// 			setParsedCustomers(customersData);

	// 			if (customersData.length > 0) {
	// 				setFormData(mapCustomerDataToFormData(customersData[0]));
	// 			}

	// 			alert(
	// 				`XML file uploaded and parsed successfully! ${customersData.length} customer(s) found.`,
	// 			);
	// 		} catch (error) {
	// 			console.error("Error parsing XML:", error);

	// 			generateToast(
	// 				"Error parsing XML file. Please make sure it's a valid XML format",
	// 				"error",
	// 			);
	// 		}
	// 	};
	// 	reader.readAsText(xmlFile);
	// };

	// const mapCustomerDataToFormData = (customerData) => ({
	// 	firstName: customerData.firstName || "",
	// 	lastName: customerData.lastName || "",
	// 	email: customerData.email || "",
	// 	phoneNumber: customerData.phoneNumber || "",
	// 	streetAddress: customerData.streetAddress || "",
	// 	city: customerData.city || "",
	// 	zipCode: customerData.zipCode || "",
	// 	agent: customerData.agent || "",
	// 	noOfPropertiesOwned: customerData.noOfPropertiesOwned || "1",
	// 	fileNumber: customerData.fileNumber || "",
	// 	scenario: customerData.scenario || "",
	// 	losLink: customerData.losLink || "",
	// 	creditScore: customerData.creditScore || "",
	// 	type: customerData.type || "any",
	// 	loanType: customerData.loanType || "1",
	// 	rate: customerData.rate || "",
	// 	miAmount: customerData.miAmount || "",
	// 	refiTargetRate: customerData.refiTargetRate || "",
	// 	employmentType: customerData.employmentType || "1",
	// 	dateModified:
	// 		customerData.dateModified || new Date().toISOString().split("T")[0],
	// 	processor: customerData.processor || "",
	// 	buyerAgent: customerData.buyerAgent || "",
	// 	listingAgent: customerData.listingAgent || "",
	// 	titleCompany: customerData.titleCompany || "",
	// 	titleAgent: customerData.titleAgent || "",
	// 	lenderName: customerData.lenderName || "",
	// 	insuranceAgent: customerData.insuranceAgent || "",
	// 	closedDate: customerData.closedDate || "",
	// });

	// const handleXmlUpload = async (event) => {
	// 	event.preventDefault();
	// 	if (!xmlFile) {
	// 		generateToast("Please Select an XML File", "info");
	// 		return;
	// 	}

	// 	const reader = new FileReader();
	// 	reader.onload = async (e) => {
	// 		const xmlContent = e.target.result;
	// 		try {
	// 			const parser = new XMLParser({
	// 				ignoreAttributes: false,
	// 				attributeNamePrefix: "",
	// 				ignoreNameSpace: true,
	// 				allowBooleanAttributes: true,
	// 			});
	// 			const result = parser.parse(xmlContent);

	// 			// Assuming only one customer is being uploaded
	// 			const customerData =
	// 				result.MESSAGE?.DEAL_SETS?.DEAL_SET?.DEALS?.DEAL?.PARTIES
	// 					?.PARTY?.[0] || {};

	// 			// Parse the customer data to map it to your formData structure
	// 			const formData = mapCustomerDataToFormData(customerData);
	// 			setFormData(formData);

	// 			alert("XML file uploaded and parsed successfully!");
	// 		} catch (error) {
	// 			console.error("Error parsing XML:", error);
	// 			generateToast(
	// 				"Error parsing XML file. Please make sure it's a valid XML format",
	// 				"error",
	// 			);
	// 		}
	// 	};
	// 	reader.readAsText(xmlFile);
	// };

	// const mapCustomerDataToFormData = (customerData) => {
	// 	const address = customerData.SUBJECT_PROPERTY?.ADDRESS || {};
	// 	console.log("Address:", address);

	// 	const loanDetails = customerData.LOANS?.LOAN?.[0]?.LOAN_DETAIL || {};
	// 	console.log("Loan Details:", loanDetails);

	// 	const individual = customerData.INDIVIDUAL || {};
	// 	console.log("Individual Data:", individual);

	// 	const contactPoints = individual.CONTACT_POINTS?.CONTACT_POINT || [];
	// 	console.log("Contact Points:", contactPoints);

	// 	const emailContact = contactPoints.find(
	// 		(point) => point.CONTACT_POINT_EMAIL,
	// 	);
	// 	console.log("Email Contact:", emailContact);

	// 	const phoneContact = contactPoints.find(
	// 		(point) => point.CONTACT_POINT_TELEPHONE,
	// 	);
	// 	console.log("Phone Contact:", phoneContact);

	// 	const formData = {
	// 		firstName: individual.NAME?.FirstName || "",
	// 		lastName: individual.NAME?.LastName || "",
	// 		email: emailContact?.CONTACT_POINT_EMAIL?.ContactPointEmailValue || "",
	// 		phoneNumber:
	// 			phoneContact?.CONTACT_POINT_TELEPHONE?.ContactPointTelephoneValue || "",
	// 		streetAddress: address.AddressLineText || "",
	// 		city: address.CityName || "",
	// 		zipCode: address.PostalCode || "",
	// 		agent: individual.IndividualTitleDescription || "", // Assuming agent refers to "Loan Officer"
	// 		noOfPropertiesOwned: "1", // Hardcoded since it's not available
	// 		fileNumber: customerData.fileNumber || "", // Not found in the XML, defaulting to ""
	// 		scenario: "", // Not found in XML
	// 		losLink: "", // Not found in XML
	// 		creditScore: "", // Not found in XML
	// 		type: customerData.type || "any", // Not clearly defined in the XML
	// 		loanType:
	// 			customerData.LOANS?.LOAN?.[0]?.TERMS_OF_LOAN?.MortgageType || "1",
	// 		rate: customerData.LOANS?.LOAN?.[0]?.TERMS_OF_LOAN?.NoteRatePercent || "",
	// 		miAmount:
	// 			customerData.LOANS?.LOAN?.[0]?.HOUSING_EXPENSES?.HOUSING_EXPENSE?.find(
	// 				(expense) => expense.HousingExpenseType === "MIPremium",
	// 			)?.HousingExpensePaymentAmount || "",
	// 		refiTargetRate: "", // Not found in XML
	// 		employmentType: "1", // Not found in XML
	// 		dateModified: new Date().toISOString().split("T")[0],
	// 		processor: "", // Not found in XML
	// 		buyerAgent: "", // Not found in XML
	// 		listingAgent: "", // Not found in XML
	// 		titleCompany:
	// 			customerData.LEGAL_ENTITY?.LEGAL_ENTITY_DETAIL?.FullName || "",
	// 		titleAgent: "", // Not found in XML
	// 		lenderName:
	// 			customerData.LOANS?.LOAN?.[0]?.ORIGINATION_SYSTEMS?.ORIGINATION_SYSTEM
	// 				?.LoanOriginationSystemName || "",
	// 		insuranceAgent: "", // Not found in XML
	// 		closedDate: "", // Not found in XML
	// 	};

	// 	console.log("Mapped formData:", formData);
	// 	return formData;
	// };

	// const handleCsvUpload = async (event) => {
	// 	event.preventDefault();
	// 	const file = event.target.files[0];
	// 	if (!file) {
	// 		generateToast("Please select a CSV file", "info");
	// 		return;
	// 	}

	// 	Papa.parse(file, {
	// 		complete: async (results) => {
	// 			const { data, meta } = results;
	// 			if (data.length === 0) {
	// 				generateToast("The CSV file is empty", "error");
	// 				return;
	// 			}

	// 			// Remove empty rows
	// 			const cleanData = data.filter((row) =>
	// 				Object.values(row).some((value) => value !== ""),
	// 			);

	// 			// Field mapping wizard (simplified version)
	// 			const fieldMapping = await promptFieldMapping(meta.fields);

	// 			const mappedData = cleanData.map((row) =>
	// 				mapRowToFormData(row, fieldMapping),
	// 			);

	// 			setCustomers(mappedData);
	// 			console.log("mapped", mappedData);
	// 			generateToast(
	// 				`Found ${mappedData.length} customers in the CSV file`,
	// 				"success",
	// 			);

	// 			// Optional: Automatically submit all customers
	// 			// await submitAllCustomers(mappedData);
	// 		},
	// 		header: true,
	// 		skipEmptyLines: true,
	// 	});
	// };

	// const promptFieldMapping = async (csvFields) => {
	// 	// This is a placeholder for a more sophisticated field mapping UI
	// 	// For now, we'll use a simple object mapping CSV fields to form fields
	// 	const defaultMapping = {
	// 		"First Name": "firstName",
	// 		"Last Name": "lastName",
	// 		Email: "email",
	// 		"Phone Number": "phoneNumber",
	// 		"Street Address": "streetAddress",
	// 		City: "city",
	// 		State: "state",
	// 		Zip: "zipCode",
	// 		"Loan Type": "loanType",
	// 		"Buyer Agent": "buyerAgent",
	// 		"Listing Agent": "listingAgent",
	// 	};

	// 	// In a real application, you would show a UI for the user to adjust this mapping
	// 	return defaultMapping;
	// };

	// const mapRowToFormData = (row, fieldMapping) => {
	// 	const formData = {
	// 		...initialFormData, // Spread your initial form data here
	// 		dateModified: new Date().toISOString().split("T")[0],
	// 	};

	// 	Object.entries(fieldMapping).forEach(([csvField, formField]) => {
	// 		if (row[csvField] !== undefined) {
	// 			formData[formField] = row[csvField];
	// 		}
	// 	});

	// 	// Special handling for loan type
	// 	formData.loanType = mapLoanType(formData.loanType);

	// 	return formData;
	// };

		// const mapLoanType = (loanType) => {
		// 	const loanTypeMap = {
		// 		FHA: "1",
		// 		USDA: "2",
		// 		Conventional: "3",
		// 		VA: "4",
		// 		Reverse: "5",
		// 		"Non-Qm": "6",
		// 	};
		// 	return loanTypeMap[loanType] || "3"; // Default to Conventional if not found
		// };

		const expectedFields = [
			"first_name",
			"last_name",
			"email",
			"phone_number",
			"address", // renamed from streetAddress
			"city",
			"state",
			"zip_code",
			"loan", // renamed from loanType
			"buyer_agent",
			"employee_type", // renamed from employmentType
			"closed_date", // renamed from closedDate
			"insurance_agent",
			"processor",
			"listing_agent",
			"refi_target_rate",
			"rate",
			"credit_score",
			"file_number"
		  ];
		  
	const getClosedDate = () => {
		const date = new Date();
		date.setMonth(date.getMonth() + 3);
		return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
	};

	const openModal = () => {
		setIsModalOpen(true);
		setStep(1);
		setFile(null);
		setHeaders([]);
		setMapping({});
		setCustomers([]);
		setError("");
	};

	const closeModal = () => setIsModalOpen(false);

	// Loan type mapping function
const mapLoanType = (loanType) => {
	const loanTypeMap = {
		FHA: "1",
		USDA: "2",
		Conventional: "3",
		VA: "4",
		Reverse: "5",
		"Non-Qm": "6",
	};
	return loanTypeMap[loanType] || "3"; // Default to Conventional if not found
};

const handleFileUpload = (event) => {
	const uploadedFile = event.target.files[0];
	setFile(uploadedFile);
	setIsLoading(true);
	setError("");

	Papa.parse(uploadedFile, {
		complete: (results) => {
			if (results.data && results.data.length > 0) {
				// Ensure headers is an array
				const parsedHeaders = Array.isArray(results.data[0])
					? results.data[0]
					: Object.keys(results.data[0]);
				setHeaders(parsedHeaders);
				setStep(2);
			} else {
				setError("The CSV file is empty or invalid");
			}
			setIsLoading(false);
		},
		header: true,
		skipEmptyLines: true,
		error: (error) => {
			setError(`Error parsing CSV: ${error.message}`);
			setIsLoading(false);
		},
	});
};

const handleMapping = (expectedField, csvField) => {
	setMapping((prev) => ({ ...prev, [expectedField]: csvField }));
};

const processCustomers = () => {
	setIsLoading(true);
	Papa.parse(file, {
		complete: (results) => {
			if (results.data && results.data.length > 0) {
				const mappedCustomers = results.data.map((row, index) => {
					const customer = {
						employee_type: "2",
						closed_date: getClosedDate(),
					};
					
					// Loop through the mapping
					Object.entries(mapping).forEach(([expectedField, csvField]) => {
						if (csvField) {
							if (expectedField === "loan") {
								// Map the loanType using mapLoanType function
								customer[expectedField] = mapLoanType(row[csvField]) || "3"; // Default to "Conventional" (3)
							} else {
								customer[expectedField] = row[csvField] || "";
							}
						}
					});
					
					return customer;
				});
				setCustomers(mappedCustomers);
				setStep(3);
			} else {
				setError("No valid data found in the CSV file");
			}
			setIsLoading(false);
		},
		header: true,
		skipEmptyLines: true,
		error: (error) => {
			setError(`Error processing CSV: ${error.message}`);
			setIsLoading(false);
		},
	});
};

	const submitAllCustomers = async () => {
		setIsLoading(true);
		const accessToken = localStorage.getItem("accessToken");
		if (!accessToken) {
			generateToast("Access token not found. Please log in again.", "error");
			setIsLoading(false);
			return;
		}

		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		};

		let successCount = 0; // Counter for successful uploads
		let errorCount = 0; // Counter for failed uploads

		for (const customer of customers) {
			try {
				// console.log("customer", customer);
				// const filteredData = filterKeys(customer);
				const response = await axiosInstance.post(
					"https://djangobk.lenditcrm.com/customer/add/",
					customer,
					{ headers },
				);

				if (response.data.code === 200) {
					successCount++; // Increment success counter
				} else {
					errorCount++; // Increment error counter
				}
			} catch (error) {
				errorCount++; // Increment error counter for any exception
			}
		}

		// Show a single toast summarizing the result
		generateToast(
			`Bulk upload completed. Successfully added: ${successCount}, Errors: ${errorCount}`,
			successCount > 0 ? "success" : "error",
		);
		closeModal()
		

		setIsLoading(false); // Stop the loading indicator
	};

	const handleXmlUpload = async (event) => {
		event.preventDefault();
		if (!xmlFile) {
			generateToast("Please Select an XML File", "info");
			return;
		}

		const reader = new FileReader();
		reader.onload = async (e) => {
			const xmlContent = e.target.result;
			try {
				const parser = new XMLParser({
					ignoreAttributes: false,
					attributeNamePrefix: "",
					ignoreNameSpace: true,
					allowBooleanAttributes: true,
				});
				const result = parser.parse(xmlContent);

				let dealData = result.MESSAGE?.DEAL_SETS?.DEAL_SET?.DEALS?.DEAL;
				if (!dealData) {
					throw new Error("Unable to find DEAL data in the XML structure");
				}

				let borrowerData = dealData.PARTIES?.PARTY?.find(
					(party) =>
						party.ROLES?.ROLE?.ROLE_DETAIL?.PartyRoleType === "Borrower",
				);

				if (borrowerData) {
					setFormData(mapCustomerDataToFormData(borrowerData, dealData));
					alert("XML file uploaded and parsed successfully!");
				} else {
					generateToast("No borrower data found in the XML file.", "error");
				}
			} catch (error) {
				console.error("Error parsing XML:", error);
				generateToast(
					`Error parsing XML file: ${error.message}. Please make sure it's a valid XML format`,
					"error",
				);
			}
		};
		reader.readAsText(xmlFile);
	};

	const mapCustomerDataToFormData = (borrowerData, dealData) => {
		const loan = dealData.LOANS?.LOAN || {};
		const property = dealData.COLLATERALS?.COLLATERAL?.SUBJECT_PROPERTY || {};
		const address = property.ADDRESS || {};

		const getContactPoint = (type) => {
			return borrowerData.INDIVIDUAL?.CONTACT_POINTS?.CONTACT_POINT?.find(
				(cp) => cp[type],
			);
		};

		const getHousingExpense = (type) => {
			return dealData.LOANS?.LOAN?.HOUSING_EXPENSES?.HOUSING_EXPENSE?.find(
				(exp) => exp.HousingExpenseType === type,
			);
		};

		const getLoanOriginator = () => {
			return dealData.PARTIES?.PARTY?.find(
				(party) =>
					party.ROLES?.ROLE?.ROLE_DETAIL?.PartyRoleType === "LoanOriginator",
			);
		};

		const getLoanOriginationCompany = () => {
			return dealData.PARTIES?.PARTY?.find(
				(party) =>
					party.ROLES?.ROLE?.ROLE_DETAIL?.PartyRoleType ===
					"LoanOriginationCompany",
			);
		};

		const mapLoanType = (mortgageType) => {
			const loanTypeMap = {
				FHA: "1",
				USDA: "2",
				Conventional: "3",
				VA: "4",
				Reverse: "5",
				"Non-Qm": "6",
			};
			return loanTypeMap[mortgageType] || "3"; // Default to Conventional if not found
		};

		const getFormattedDate = (date) => {
			if (date instanceof Date && !isNaN(date)) {
				return date.toISOString().split("T")[0];
			}
			return new Date().toISOString().split("T")[0]; // Default to current date if invalid
		};
		const getFutureDateString = (daysInFuture) => {
			const futureDate = new Date();
			futureDate.setDate(futureDate.getDate() + daysInFuture);
			return getFormattedDate(futureDate);
		};

		return {
			firstName: borrowerData.INDIVIDUAL?.NAME?.FirstName || "",
			lastName: borrowerData.INDIVIDUAL?.NAME?.LastName || "",
			email:
				getContactPoint("CONTACT_POINT_EMAIL")?.CONTACT_POINT_EMAIL
					?.ContactPointEmailValue || "",
			phoneNumber:
				getContactPoint("CONTACT_POINT_TELEPHONE")?.CONTACT_POINT_TELEPHONE
					?.ContactPointTelephoneValue || "",
			streetAddress: address.AddressLineText || "",
			city: address.CityName || "",
			zipCode: address.PostalCode || "",
			agent: getLoanOriginator()?.INDIVIDUAL?.NAME?.FullName || "",
			noOfPropertiesOwned:
				loan.LOAN_DETAIL?.TotalMortgagedPropertiesCount || "1",
			fileNumber: loan.LOAN_IDENTIFIERS?.LOAN_IDENTIFIER?.LoanIdentifier || "",
			scenario: "", // Not available in the provided XML
			losLink: "", // Not available in the provided XML
			creditScore: "", // Not available in the provided XML
			type: property.PROPERTY_DETAIL?.PropertyUsageType || "any",
			loanType: mapLoanType(loan.TERMS_OF_LOAN?.MortgageType),
			rate: loan.TERMS_OF_LOAN?.NoteRatePercent || "",
			miAmount:
				getHousingExpense("MIPremium")?.HousingExpensePaymentAmount || "",
			refiTargetRate: "", // Not available in the provided XML
			employmentType: "1", // Not available in the provided XML
			dateModified: getFormattedDate(new Date()),
			processor: "", // Not available in the provided XML
			buyerAgent: "", // Not available in the provided XML
			listingAgent: "", // Not available in the provided XML
			titleCompany: "", // Not available in the provided XML
			titleAgent: "", // Not available in the provided XML
			lenderName:
				getLoanOriginationCompany()?.LEGAL_ENTITY?.LEGAL_ENTITY_DETAIL
					?.FullName || "",
			insuranceAgent: "", // Not available in the provided XML
			closedDate: getFutureDateString(20), // Set to 20 days in the future
		};
	};
	const handleCustomerSelect = (index) => {
		setSelectedCustomerIndex(index);
		setFormData(mapCustomerDataToFormData(parsedCustomers[index]));
	};

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		phoneNumber: "",
		streetAddress: "",
		email:"",
		city: "",
		zipCode: "",
		agent: "",
		noOfPropertiesOwned: "1",
		fileNumber: "",
		scenario: "",
		losLink: "",
		leadSource: "",
		creditScore: "",
		type: "any",
		loanType: "1",
		rate: "",
		miAmount: "",
		refiTargetRate: "",
		employmentType: "1",
		dateModified: new Date().toISOString().split("T")[0],
		closedDate: null,
		state: "",
	});

	const [noteData, setNoteData] = useState({
		title: "",
		description: "",
	});
	useEffect(() => {
		fetchDatanotes();
	}, [refresh]);

	const handleChange = (event) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const fetchDatanotes = async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/customer/note_list/",
			);
			console.log(response.data.data);
			setNotesDataList(response.data.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const filterKeys = (formData) => {
		const filteredData = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			phone_number: formData.phoneNumber,
			address: formData.streetAddress,
			email:formData.email,
			city: formData.city,
			zip_code: formData.zipCode,
			agent: formData.agent,
			property_own: 1,
			file_number: formData.fileNumber,
			scenario: formData.scenario,
			los_link: formData.losLink,
			credit_score: parseInt(formData.creditScore),
			type: formData.type,
			loan: parseInt(formData.loanType),
			rate: formData.rate,
			mi_amount: formData.miAmount,
			refi_target_rate: formData.refiTargetRate,
			date_modified: formData.dateModified,
			employee_type: parseInt(formData.employmentType),
			processor: formData.processor, // added processor field
			buyer_agent: formData.buyerAgent, // added buyerAgent field
			listing_agent: formData.listingAgent, // added listingAgent field
			title_company: formData.titleCompany, // added titleCompany field
			title_agent: formData.titleAgent, // added titleAgent field
			lender_name: formData.lenderName, // added lenderName field
			insurance_agent: formData.insuranceAgent, // added insuranceAgent field
			closed_date: formData.closedDate, // added closedDate field
			state: formData.state,
			status: "Active",
		};
		return filteredData;
	};

	const handleNoteChange = (event) => {
		console.log(event);
		setNoteData({ ...noteData, [event.target.name]: event.target.value });
		console.log(noteData);
	};

	const handleClearNote = (event) => {
		event.preventDefault();
		setNoteData(initialNoteData);
		console.log("Clear Note btn");
	};

	const handleAddNote = (event) => {
		event.preventDefault();

		const { title, description } = noteData;
		const payload = {
			title: title,
			note: description,
		};

		axiosInstance
			.post("https://djangobk.lenditcrm.com/customer/note_add/", payload)
			.then((response) => {
				console.log("Note added successfully:", response.data);
				alert("note added successfully");
				setRefresh(!refresh);
			})
			.catch((error) => {
				console.error("Error adding note:", error);
			});
	};
	const Openlink = (link) => {
		// Check if the link starts with 'http://' or 'https://'
		const validLink =
			link.startsWith("http://") || link.startsWith("https://")
				? link
				: `https://${link}`; // Add 'https://' if not present

		// Open the valid link in a new tab
		window.open(validLink, "_blank");
	};

	const handleClear = (event) => {
		event.preventDefault();
		setFormData(initialFormData);
		//console.log("Discard btn clicked")
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const accessToken = localStorage.getItem("accessToken");
			if (!accessToken) {
				console.error("Access token not found in localStorage");
				return;
			}
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			};
			console.log("bedor form data", formData);
			const filteredData = filterKeys(formData);
			console.log(filteredData);
			const response = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/customer/add/",
				filteredData,
				{
					headers: headers,
				},
			);
			console.log(response.data.code);
			if (response.data.code === 200) {
				alert("Customer added successfully!");
				generateToast("Customer Added successfully ", "success");
				// nav("/listing");
			} else {
				alert("Failed to add customer");
				generateToast(
					`Failed to add customer ${response.data.messages}`,
					"error",
				);
			}
		} catch (error) {
			console.error("Error:", error);
			generateToast(`Error ${error}`, "error");
		}
	};
	const toggleXmlUpload = () => {
		setShowXmlUpload(!showXmlUpload);
		// Reset parsed customers and selected index when toggling
		setParsedCustomers([]);
		setSelectedCustomerIndex(null);
	};
	// const openModal = () => setIsModalOpen(true);
	// const closeModal = () => {
	// 	setIsModalOpen(false);
	// 	setCustomers([]);
	// };
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleDropdownToggle = () => {
	  setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<>
			<Header />
			<div className="flex mx-6">
				<div className="form-container w-2/3 border border-gray-300 rounded-lg shadow-md  mt-10 ml-6 mr-6 px-4 py-8">
				<h1 className="text-3xl flex items-start justify-start font-bold mb-6">Customer Management</h1>
				<div className="relative inline-block text-left">
	<button
		onClick={handleDropdownToggle}
		className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
	>
		Upload Options
		<FaCaretDown className="ml-2" />
	</button>
	{isDropdownOpen && (
		<div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
			<div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
				{/* Show XML Upload button */}
				<button
					onClick={() => {
						toggleXmlUpload();
						setIsDropdownOpen(false); // Close the dropdown
					}}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
				>
					{showXmlUpload ? "Hide XML Upload" : "Show XML Upload"}
				</button>

				{/* Upload CSV button */}
				<button
					onClick={() => {
						openModal();
						setIsDropdownOpen(false); // Close the dropdown
					}}
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
				>
					Upload CSV
				</button>
			</div>
		</div>
	)}
</div>

					
					{isModalOpen && (
						<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
							<div className="relative top-40 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
								<div className="mt-3">
									<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
										CSV Upload and Mapping Wizard - Step {step} of 3
									</h3>
									{step === 1 && (
										<div>
											<p className="text-sm text-gray-500 mb-4">
												Upload your CSV file
											</p>
											<input
												type="file"
												accept=".csv"
												onChange={handleFileUpload}
												className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100"
											/>
										</div>
									)}
									{step === 2 && (
										<div>
											<p className="text-sm text-gray-500 mb-4">
												Map your CSV fields to our expected fields
											</p>
											<div className="grid grid-cols-2 gap-4">
												{expectedFields
													.filter(
															(field) =>
																!["employee_type", "closed_date"].includes(field),
														)
													.map((field) => (
														<div key={field} className="flex flex-col">
															<label className="mb-1 text-sm font-medium text-gray-700">
																{field}
															</label>
															<select
																value={mapping[field] || ""}
																onChange={(e) =>
																	handleMapping(field, e.target.value)
																}
																className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															>
																<option value="">Select a field</option>
																{headers.map((header) => (
																	<option key={header} value={header}>
																		{header}
																	</option>
																))}
															</select>
														</div>
													))}
											</div>
											
											<button
												onClick={processCustomers}
												className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
											>
												Process Customers
											</button>
										</div>
									)}
									{step === 3 && (
										<div>
											<p className="text-sm text-gray-500 mb-4">
												Review and upload customers
											</p>
											<p className="text-green-600 mb-2">
												Customers found: {customers.length}
											</p>
											<div className="max-h-60 overflow-y-auto">
												<table className="min-w-full divide-y divide-gray-200">
													<thead className="bg-gray-50">
														<tr>
															{expectedFields.map((field) => (
																<th
																	key={field}
																	className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
																>
																	{field}
																</th>
															))}
														</tr>
													</thead>
													<tbody className="bg-white divide-y divide-gray-200">
														{customers.slice(0, 5).map((customer) => (
															<tr key={customer.id}>
																{expectedFields.map((field) => (
																	<td
																		key={field}
																		className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
																	>
																		{customer[field]}
																	</td>
																))}
															</tr>
														))}
													</tbody>
												</table>
											</div>
											<button
												onClick={submitAllCustomers}
												className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
											>
												Upload All Customers
											</button>
										</div>
									)}
									{isLoading && (
										<p className="text-gray-600 mt-2">Loading...</p>
									)}
									{error && <p className="text-red-500 mt-2">{error}</p>}
									<div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
												onClick={closeModal}
											>
												Close
											</button>
										</div>
								</div>
							</div>
						</div>
					)}

					<div className="max-w-4xl mx-auto p-6">
						

						{/* Dropdown menu */}
						
						</div>
						

						{showXmlUpload && (
							<div className="fixed z-10 inset-0 overflow-y-auto">
								<div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
									<div className="fixed inset-0 transition-opacity" aria-hidden="true">
										<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
									</div>

									{/* This is the actual modal */}
									<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
									<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
										<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
											<div className="sm:flex sm:items-start">
												<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
													<h2 className="text-xl font-semibold mt-4 mb-4">
														Upload and Parse XML
													</h2>
													<form onSubmit={handleXmlUpload} className="mb-6">
														<div className="flex items-center space-x-4">
															<input
																type="file"
																accept=".xml"
																onChange={handleFileChange}
																className="file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
															/>
															<button
																type="submit"
																className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
															>
																Upload XML
															</button>
														</div>
													</form>

													{parsedCustomers.length > 0 && (
														<div>
															<h3 className="text-lg font-semibold mb-2">
																Select a Customer:
															</h3>
															<ul className="bg-white rounded shadow-md p-4">
																{parsedCustomers.map((customer, index) => (
																	<li
																		key={index}
																		onClick={() => handleCustomerSelect(index)}
																		className={`cursor-pointer p-2 hover:bg-gray-100 ${
																			selectedCustomerIndex === index
																				? "bg-blue-100 font-semibold"
																				: ""
																		}`}
																	>
																		{customer.firstName} {customer.lastName}
																	</li>
																))}
															</ul>
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
												onClick={toggleXmlUpload}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					<h2 className="flex justify-content-start text-xl font-bold ml-8">
						Create New Record
					</h2>
					<form onSubmit={handleSubmit} className="bg-white rounded px-4 py-5">
					<div className="flex flex-wrap -mx-3 mb-6">
					{[
						{ id: "firstName", label: "First Name", type: "text" },
						{ id: "lastName", label: "Last Name", type: "text" },
						{ id: "email", label: "Email", type: "email" },
						{ id: "phoneNumber", label: "Phone Number", type: "text" },
						{ id: "streetAddress", label: "Street Address", type: "text" },
						{ id: "city", label: "City", type: "text" },
						
						{ id: "state", label: "State", type: "select", options: [
							"Alabama",
							"Alaska",
							"Arizona",
							"Arkansas",
							"California",
							"Colorado",
							"Connecticut",
							"Delaware",
							"Florida",
							"Georgia",
							"Hawaii",
							"Idaho",
							"Illinois",
							"Indiana",
							"Iowa",
							"Kansas",
							"Kentucky",
							"Louisiana",
							"Maine",
							"Maryland",
							"Massachusetts",
							"Michigan",
							"Minnesota",
							"Mississippi",
							"Missouri",
							"Montana",
							"Nebraska",
							"Nevada",
							"New Hampshire",
							"New Jersey",
							"New Mexico",
							"New York",
							"North Carolina",
							"North Dakota",
							"Ohio",
							"Oklahoma",
							"Oregon",
							"Pennsylvania",
							"Rhode Island",
							"South Carolina",
							"South Dakota",
							"Tennessee",
							"Texas",
							"Utah",
							"Vermont",
							"Virginia",
							"Washington",
							"West Virginia",
							"Wisconsin",
							"Wyoming",
						] },
						
						{ id: "zipCode", label: "Zip Code", type: "text" },
						{ id: "agent", label: "Buyer Agent", type: "text" },
						{ id: "fileNumber", label: "File Number", type: "text" },
					].map((field) => (
						<div key={field.id} className="w-full md:w-1/3 px-3 mb-6 md:mb-2">
						<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={field.id}>
							{field.label}
						</label>
						{field.type === 'select' ? (
							<select
							id={field.id}
							name={field.id}
							value={formData[field.id]}
							onChange={handleChange}
							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							>
							<option value="">Select {field.label}</option>
							{field.options.map((option) => (
								<option key={option} value={option}>
								{option}
								</option>
							))}
							</select>
						) : (
							<input
							type={field.type}
							id={field.id}
							name={field.id}
							value={formData[field.id]}
							onChange={handleChange}
							className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
							/>
						)}
						</div>
					))}

					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-2">
						<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="noOfPropertiesOwned">
						Properties Owned
						</label>
						<select
						id="noOfPropertiesOwned"
						name="noOfPropertiesOwned"
						value={formData.noOfPropertiesOwned}
						onChange={handleChange}
						className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
						>
						<option value="1">Properties 1</option>
						<option value="2">Properties 2</option>
						</select>
					</div>
					</div>


						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor="scenario"
								>
									Scenario
								</label>
								<textarea
									id="scenario"
									name="scenario"
									rows={4}
									value={formData.scenario}
									onChange={handleChange}
									className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
									placeholder="Type Here"
								/>
							</div>
						</div>

						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
									htmlFor="losLink"
								>
									Enter LOS Link
								</label>
								<div className="flex">
									<input
										type="text"
										id="losLink"
										name="losLink"
										value={formData.losLink}
										onChange={handleChange}
										className="flex-grow px-3 py-2 text-gray-700 border rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
									/>
									<button
										type="button"
										onClick={() =>
											formData.losLink && Openlink(formData.losLink)
										}
										className="bg-[#028ec8] hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-r-md"
									>
										View Link
									</button>
								</div>
							</div>
						</div>

						{/* Additional fields */}
						<div className="flex flex-wrap -mx-3 mb-6">
							{[
								{ id: "creditScore", label: "Credit Score", type: "text" },
								{
									id: "type",
									label: "Purpose",
									type: "select",

									options: [
										{ value: "Purchase", label: "Purchase" },
										{ value: "Rate and Term", label: "Rate and Term" },
										{ value: "Cash Out", label: "Cash Out" },
										{ value: "Streamline", label: "Streamline" },
										{ value: "HELOC", label: "HELOC" },
										{ value: "Reverse", label: "Reverse" },
										{ value: "Other", label: "Other" },
									],
								},
								{
									id: "loanType",
									label: "Loan Type",
									type: "select",
									options: [
										{ value: "1", label: "FHA" },
										{ value: "2", label: "USDA" },
										{ value: "3", label: "Conventional" },
										{ value: "4", label: "VA" },
										{ value: "5", label: "Reverse" },
										{ value: "6", label: "Non-Qm" },
									],
								},
								{ id: "rate", label: "Rate", type: "text" },
								{ id: "miAmount", label: "Mi Amount", type: "text" },
								{
									id: "refiTargetRate",
									label: "Refi Target Rate",
									type: "text",
								},
								{
									id: "employmentType",
									label: "Employment Type",
									type: "select",
									options: [
										{ value: "1", label: "Fixed Income" },
										{ value: "2", label: "W2" },
										{ value: "3", label: "Self-Employed" },
									],
								},
								{ id: "dateModified", label: "Date Modified", type: "date" },
							].map((field) => (
								<div
									key={field.id}
									className="w-full md:w-1/3 px-3 mb-6 md:mb-2"
								>
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor={field.id}
									>
										{field.label}
									</label>
									{field.type === "select" ? (
										<select
											id={field.id}
											name={field.id}
											value={formData[field.id]}
											onChange={handleChange}
											className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
										>
											{field.options.map((option) => (
												<option key={option.value} value={option.value}>
													{option.label}
												</option>
											))}
										</select>
									) : (
										<input
											type={field.type}
											id={field.id}
											name={field.id}
											value={formData[field.id]}
											onChange={handleChange}
											className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
										/>
									)}
								</div>
							))}
						</div>

						{/* Loan Contact button */}
						<div className="mt-2 flex justify-center items-center mb-6">
							<button
								type="button"
								className="bg-[#1e3a8a] rounded-md flex items-center justify-between text-white text-left text-2xl w-full py-2 px-4"
							>
								Loan Contacts
							</button>
						</div>

						{/* Additional contact fields */}
						<div className="flex flex-wrap -mx-3 mb-6">
							{[
								{ id: "processor", label: "Processor Name" },
								{ id: "buyerAgent", label: "Buyer Agent Name" },
								{ id: "listingAgent", label: "Listing Agent Name" },

								{ id: "titleCompany", label: "Title Company" },
								{ id: "titleAgent", label: "Title Agent Name" },
								{ id: "lenderName", label: "Lender Name" },
								{ id: "insuranceAgent", label: "Insurance Agent Name" },
								{ id: "closedDate", label: "Closed Date", type: "date" },
							].map((field) => (
								<div
									key={field.id}
									className="w-full md:w-1/2 px-3 mb-6 md:mb-2"
								>
									<label
										className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
										htmlFor={field.id}
									>
										{field.label}
									</label>
									<input
										type={field.type || "text"}
										id={field.id}
										name={field.id}
										value={formData[field.id]}
										onChange={handleChange}
										className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
									/>
								</div>
							))}
						</div>

						{/* Form buttons */}
						<div className="flex justify-end items-center space-x-4 mt-10">
							<button
								type="button"
								className="text-base bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
								onClick={handleClear}
							>
								Discard
							</button>
							<button
								type="submit"
								className="text-base bg-[#028ec8] hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-lg"
							>
								Save
							</button>
						</div>
					</form>
				</div>
				<NotesApp />
				{/* <div className="mt-10 w-1/3  h-full report-container border border-gray-300 rounded-lg shadow-md">
					<div className="bg-[#028ec8] text-white p-3 text-left text-xl">
						<p>Add Notes</p>
					</div>
					<p className="text-lg text-left mt-3 ml-14 mb-2">Note Title</p>
					<input
						className="border  w-3/4 px-3 py-2 text-gray-700 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
						type="text"
						id="title"
						name="title"
						placeholder="Title"
						value={noteData.title}
						onChange={handleNoteChange}
					/>
					<p className="text-lg text-left mt-3 ml-14 mb-2">Note Description</p>
					<textarea
						className="border  w-3/4 px-3 py-2 text-gray-700 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
						type="text"
						id="description"
						name="description"
						placeholder="Type Here"
						rows={4}
						cols={50}
						value={noteData.description}
						onChange={handleNoteChange}
					/>
					<div className="mt-6 ml-64 mb-2">
						<button
							className="text-base bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded-lg mr-2"
							onClick={handleClearNote}
						>
							Discard
						</button>
						<button
							className="text-base bg-[#028ec8] hover:bg-indigo-700 text-white font-bold py-1 px-4 rounded-lg"
							onClick={handleAddNote}
						>
							Add
						</button>
					</div>
					<div className="mt-10 rounded-lg bg-gray-200 shadow-md p-4 text-center">
						<h3>More Recent</h3>
					</div>
					<div className="mt-10 rounded-lg bg-gray-200 shadow-md p-4 text-center">
						<p className="text-2xl font-bold">{notesdatalist[0]?.title}</p>
						<h3>{notesdatalist[0]?.note}</h3>
					</div>
					<div className="mt-10 rounded-lg bg-gray-200 shadow-md p-4 text-center mt-2">
						<p className="text-2xl font-bold">{notesdatalist[1]?.title}</p>
						<h3>{notesdatalist[1]?.note}</h3>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default AddCustomer;
