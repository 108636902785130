import React, { useState, useEffect, useCallback } from "react";
import Header from "../Header";
import axiosInstance from "../../axiosInstance";
import { useToast } from "../../Context/ToastContext";
import { useAuth } from "../../components/AuthToken";
// Card component
const Card = ({ title, progressText, count, onDelete, onEdit }) => (
	<div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
		<div className="flex justify-between items-center p-4">
			<h2 className="font-semibold text-lg">{title}</h2>
			<button className="text-gray-500 hover:text-gray-700" onClick={onEdit}>
				✎
			</button>
		</div>

		<div className="p-4">
			<p className="text-gray-600 mb-2">{progressText}</p>
			<div className="flex items-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5 text-blue-500 mr-1"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
						clipRule="evenodd"
					/>
				</svg>
				<span className="text-blue-500">{count}</span>
			</div>
		</div>
		<button
			className="w-full bg-gray-100 text-gray-700 py-2 px-4 hover:bg-gray-200 flex items-center justify-center"
			onClick={onDelete}
		>
			🗑️ Delete Card
		</button>
	</div>
);

// Modal component for editing card

const EditCardModal = ({ isOpen, card, onClose, onUpdate }) => {
	const [title, setTitle] = useState("");
	const [imageFile, setImageFile] = useState(null);
	const [description, setDescription] = useState("");

	useEffect(() => {
		if (card) {
			setTitle(card.title);
			setDescription(card.description || "");
			setImageFile(null);
		}
	}, [card]);

	// Mock upload function (replace with real upload logic)

	const handleUpdate = async () => {
		onUpdate({
			...card,
			title,
			description,
		});
		onClose();
	};

	if (!isOpen || !card) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
			<div className="bg-white rounded-lg shadow-xl w-full max-w-md">
				<div className="flex items-center justify-between p-4 border-b">
					<h2 className="text-xl font-semibold text-gray-800">{title}</h2>
					<button
						onClick={onClose}
						className="text-gray-500 hover:text-gray-700"
					>
						<span>&times;</span> {/* Simple 'X' icon replacement */}
					</button>
				</div>
				<div className="p-4">
					<div className="flex items-center justify-between mb-4">
						<h3 className="text-lg font-medium text-gray-700">Description</h3>
						<button className="text-blue-600 hover:text-blue-800 flex items-center">
							<span>Edit</span> {/* Replace Edit2 icon */}
						</button>
					</div>
					<textarea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						className="w-full p-2 border rounded-md mb-4 h-24 resize-none"
						placeholder="Add a more detailed description..."
					/>
					{/* <button className="flex items-center text-gray-600 hover:text-gray-800 mb-4">
						<span>Attach</span> 
					</button> */}

					{/* <input
						type="file"
						onChange={(e) => setImageFile(e.target.files[0])}
						className="hidden"
						id="imageUpload"
					/>
					<label
						htmlFor="imageUpload"
						className="block w-full text-center py-2 px-4 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
					>
						Choose new image
					</label> */}
				</div>
				<div className="flex justify-end p-4 border-t">
					<button
						onClick={handleUpdate}
						className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

// Column component
const Column = ({ title, cards, onDeleteCard, onEditCard }) => (
	<div className="bg-gray-100 rounded-lg p-4 w-72 flex-shrink-0">
		<h2 className="font-semibold text-lg mb-4 text-gray-700">{title}</h2>
		{cards.map((card) => (
			<Card
				key={card.id}
				title={card.title}
				count={card.attachments || 0}
				onDelete={() => onDeleteCard(card.uuid)}
				onEdit={() => onEditCard(card)}
			/>
		))}
		{cards.length === 0 && (
			<div className="text-gray-500 text-center py-4">No cards yet</div>
		)}
	</div>
);
const ActiveFiles = () => {
	const { userId } = useAuth();
	const { generateToast } = useToast();
	const [customerData, setCustomerData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredCustomers, setFilteredCustomers] = useState([]);
	const [showSearch, setShowSearch] = useState(false);
	const [columns, setColumns] = useState([
		{ title: "Active Leads", cards: [] },
		{ title: "Application", cards: [] },
		{ title: "Preapproved", cards: [] },
		{ title: "Underwriting", cards: [] },
	]);
	const [selectedColumn, setSelectedColumn] = useState("");
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [editCard, setEditCard] = useState(null);
	const [editModalOpen, setEditModalOpen] = useState(false);

	const fetchCustomerList = useCallback(async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/customer/list/",
			);
			if (response.data.code === 200) {
				setCustomerData(response.data.data);
			} else {
				console.error("Error fetching customer list:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	const fetchActiveFiles = useCallback(async () => {
		try {
			const response = await axiosInstance.get(
				`https://djangobk.lenditcrm.com/active-files/active-files/user/${userId}`,
			);
			if (response.data.status_code === 200) {
				const activeFiles = response.data.data;
				console.log("active files", activeFiles);
				const updatedColumns = columns.map((column) => ({
					...column,
					cards: activeFiles.filter((file) => file.column === column.title),
				}));

				setColumns(updatedColumns);
			} else {
				console.error("Error fetching active files:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, [columns]);

	useEffect(() => {
		fetchCustomerList();
	}, [fetchCustomerList]);
	useEffect(() => {
		fetchActiveFiles();
	}, []);

	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);
		const filtered = customerData.filter((customer) =>
			`${customer.first_name} ${customer.last_name}`
				.toLowerCase()
				.includes(query),
		);
		setFilteredCustomers(filtered);
	};

	// const handleAddCard = () => {
	// 	if (!selectedCustomer || !selectedColumn) return;

	// 	const card = {
	// 		id: selectedCustomer.id,
	// 		title: `${selectedCustomer.first_name} ${selectedCustomer.last_name}`,
	// 		columns: selectedColumn,
	// 	};
	// 	console.log("active card", card);

	// 	const updatedColumns = columns.map((column) => {
	// 		if (column.title === selectedColumn) {
	// 			return { ...column, cards: [...column.cards, card] };
	// 		}
	// 		return column;
	// 	});
	// 	console.log("update columns", updatedColumns);

	// 	setColumns(updatedColumns);
	// 	setShowSearch(false);
	// 	setSearchQuery("");
	// 	setSelectedCustomer(null);
	// 	setSelectedColumn("");
	// };

	const handleAddCard = async () => {
		if (!selectedCustomer || !selectedColumn) return;

		const card = {
			id: selectedCustomer.id,
			title: `${selectedCustomer.first_name} ${selectedCustomer.last_name}`,
			columns: selectedColumn,
		};

		try {
			const addResponse = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/active-files/active-files/",
				{
					user: userId,
					customer: selectedCustomer.id,
					title: card.title,
					column: selectedColumn,
				},
			);
			console.log("add response", addResponse);

			// Update the state after successful creation
			const updatedColumns = columns.map((column) => {
				if (column.title === selectedColumn) {
					return { ...column, cards: [...column.cards, card] };
				}
				return column;
			});
			generateToast("Added Successfully", "success");
			fetchActiveFiles();
			console.log("update columns", updatedColumns);
			setColumns(updatedColumns);
			setShowSearch(false);
			setSearchQuery("");
			setSelectedCustomer(null);
			setSelectedColumn("");
		} catch (error) {
			console.error("Error adding card:", error);
			generateToast(`Error adding card ${error}`, "error");
		}
	};
	// const handleDeleteCard = (cardId) => {
	// 	const updatedColumns = columns.map((column) => ({
	// 		...column,
	// 		cards: column.cards.filter((card) => card.id !== cardId),
	// 	}));
	// 	setColumns(updatedColumns);
	// };
	const handleDeleteCard = async (cardId) => {
		try {
			const deleteResponse = await axiosInstance.delete(
				`https://djangobk.lenditcrm.com/active-files/active-files/${cardId}/`,
			);
			console.log("delete response", deleteResponse);

			if (deleteResponse.status === 204) {
				fetchActiveFiles();
				generateToast("Deleted Successfully", "success");
			}
		} catch (error) {
			console.error("Error deleting card:", error);
			generateToast(`Error deleteing card ${error}`, "error");
		}
	};
	const handleEditCard = (card) => {
		if (card) {
			setEditCard(card);
			setEditModalOpen(true);
		}
	};

	// const handleUpdateCard = (updatedCard) => {
	// 	const updatedColumns = columns.map((column) => ({
	// 		...column,
	// 		cards: column.cards.map((card) => {
	// 			if (card.id === updatedCard.id) {
	// 				return updatedCard;
	// 			}
	// 			return card;
	// 		}),
	// 	}));

	// 	setColumns(updatedColumns);
	// 	console.log("update card", updatedColumns, updatedCard);
	// 	setEditModalOpen(false);
	// };
	const handleUpdateCard = async (updatedCard) => {
		try {
			const updateResponse = await axiosInstance.put(
				`https://djangobk.lenditcrm.com/active-files/active-files/${updatedCard.uuid}/`,
				{
					title: updatedCard.title,
					description: updatedCard.description,
					column: updatedCard.column,
				},
			);
			console.log("update response", updateResponse);

			if (updateResponse.data.status_code === 200) {
				generateToast("Card updated Successfully", "success");
				fetchActiveFiles();
			}

			setEditModalOpen(false);
		} catch (error) {
			console.error("Error updating card:", error);
			generateToast(`Error updating card: ${error}`, "error");
		}
	};
	return (
		<>
			<Header />
			<div className="p-6 bg-gray-50 min-h-screen">
				<h1 className="font-bold text-left text-3xl text-gray-800 mb-8">
					Active Files
				</h1>

				<div className="bg-white rounded-lg shadow-md p-6 mb-8">
					<div className="flex space-x-4 mb-4">
						<input
							type="text"
							placeholder="Search customers..."
							value={searchQuery}
							onChange={handleSearch}
							className="flex-grow border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
						<button
							onClick={() => setShowSearch(!showSearch)}
							className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 transition duration-300"
						>
							{showSearch ? "Hide Search" : "Show Search"}
						</button>
					</div>

					{showSearch && (
						<div className="space-y-4">
							<select
								onChange={(e) => {
									const selectedCustomer = filteredCustomers.find(
										(customer) => customer.id == e.target.value,
									);
									setSelectedCustomer(selectedCustomer);
								}}
								className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="">Choose a customer</option>
								{filteredCustomers.map((customer) => (
									<option key={customer.id} value={customer.id}>
										{customer.first_name} {customer.last_name}
									</option>
								))}
							</select>

							<select
								value={selectedColumn}
								onChange={(e) => setSelectedColumn(e.target.value)}
								className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="">Select a column</option>
								{columns.map((column, index) => (
									<option key={index} value={column.title}>
										{column.title}
									</option>
								))}
							</select>

							<button
								onClick={handleAddCard}
								className="w-full bg-green-500 text-white rounded-md px-4 py-2 hover:bg-green-600 transition duration-300"
							>
								Add Card
							</button>
						</div>
					)}
				</div>

				<div className="flex space-x-6 overflow-x-auto pb-4">
					{columns.map((column, index) => (
						<Column
							key={index}
							title={column.title}
							cards={column.cards}
							onDeleteCard={handleDeleteCard}
							onEditCard={handleEditCard}
						/>
					))}
				</div>
			</div>
			<EditCardModal
				isOpen={editModalOpen}
				card={editCard}
				onClose={() => setEditModalOpen(false)}
				onUpdate={handleUpdateCard}
			/>
		</>
	);
};

export default ActiveFiles;
