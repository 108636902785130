import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TfiCommentAlt } from "react-icons/tfi";
import { FaUserPlus, FaEnvelope, FaTrash } from "react-icons/fa";
import axiosInstance from "../axiosInstance";

import Dashboard from "./DashBoard";
import Delete from "./Delete";
import EditCustomer from "./Customer/EditCustomer";
import ViewCustomer from "./Customer/ViewCustomer";
import { useToast } from "../Context/ToastContext";
import UploadBulkModal from "./components/UploadBulkModal";
import EmailModal from "./components/EmailModal";
import sendBulkEmail from "./components/sendBulkEmail";

const Listing = () => {
	const nav = useNavigate();
	const { generateToast } = useToast();
	const [rowData, setRowData] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [status, setStatus] = useState(""); // State to store status input
	const [modalState, setModalState] = useState({
		isDeleteModalOpen: false,
		isEditModalOpen: false,
		isViewModalOpen: false,
		selectedCustomer: null,
	});

	const fetchCustomerList = useCallback(async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/customer/list/",
			);
			if (response.data.code === 200) {
				setRowData(response.data.data);
				
			} else {
				console.error("Error fetching customer list:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	useEffect(() => {
		fetchCustomerList();
	}, [fetchCustomerList]);

	const handleDelete = async (ids) => {
		try {
			await Promise.all(
				ids.map((id) =>
					axiosInstance.get(
						`https://djangobk.lenditcrm.com/customer/delete/?id=${id}`,
					),
				),
			);
			generateToast("Selected customers deleted successfully", "success");
			fetchCustomerList();
			setSelectedRows([]);
		} catch (error) {
			generateToast(`Error deleting customers: ${error}`, "error");
			console.error("Error deleting customers:", error);
		}
	};
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const handleMassEmail = () => {
		const emails = selectedRows.map((row) => row.email).filter((email) => email !== null && email !== undefined);
		
		if (emails.length === 0) {
			generateToast("No valid emails selected. Please select recipients.", "error");
			return;
		}
		setSelectedEmails(emails);
		setModalOpen(true);
		// Implement your mass email logic here
	};
	const handleCloseModal = () => setModalOpen(false);

	const handleSendEmail = (emails, subject, body) => {
		sendBulkEmail(emails, subject, body);
		setModalOpen(false);
	  };
	

	// Handle status update
	const handleStatusUpdate = async () => {
		if (!status) {
			generateToast("Please enter a status before updating.", "error");
			return;
		}

		try {
			const accessToken = localStorage.getItem("accessToken");
			if (!accessToken) {
				console.error("Access token not found in localStorage");
				return;
			}

			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			};

			// Loop over each selected row and make individual API calls
			await Promise.all(
				selectedRows.map(async (row) => {
					const response = await axiosInstance.post(
						"https://djangobk.lenditcrm.com/customer/update/",
						{
							id: row.id, // Send ID and status one at a time
							status,
						},
						{ headers: headers },
					);

					if (response.status !== 200 && !response.ok) {
						throw new Error(`Failed to update status for ID ${row.id}`);
					}
				}),
			);

			generateToast(
				"Status updated successfully for selected customers.",
				"success",
			);
			fetchCustomerList(); // Refresh the list
			setSelectedRows([]); // Clear selected rows
			setStatus(""); // Reset status field
		} catch (error) {
			generateToast(`Error updating status: ${error.message}`, "error");
			console.error("Error updating status:", error);
		}
	};

	const ActionsRenderer = ({ data }) => (
		<div className="flex mt-1 text-3xl">
			<TfiCommentAlt
				className="text-blue-500 hover:text-gray-700 cursor-pointer mr-4"
				onClick={() =>
					setModalState({ isViewModalOpen: true, selectedCustomer: data })
				}
			/>
			<RiDeleteBin5Line
				className="text-red-500 hover:text-gray-700 cursor-pointer mr-2"
				onClick={() =>
					setModalState({ isDeleteModalOpen: true, selectedCustomer: data })
				}
			/>
			<MdOutlineEdit
				className="text-green-500 hover:text-gray-700 cursor-pointer"
				onClick={() =>
					setModalState({ isEditModalOpen: true, selectedCustomer: data })
				}
			/>
		</div>
	);

	const [columnDefs] = useState([
		{ headerCheckboxSelection: true, checkboxSelection: true, width: 50 },
		{ headerName: "ID", field: "id", filter: true },
		{
			headerName: "FULL NAME",
			valueGetter: (params) =>
				`${params.data.first_name} ${params.data.last_name}`,
			filter: true,
		},
		{ headerName: "CITY", field: "city", filter: true },
		{
			headerName: "PHONE NUMBER",
			field: "phone_number",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "LAST UPDATED",
			field: "date_modified",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "STATUS",
			field: "status",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "LOAN TYPE",
			field: "loan",
			filter: true,
			valueGetter: (params) => {
			  const loanTypes = ["", "FHA", "USDA", "Conventional", "VA", "Reverse", "Non-QM"];
			  return loanTypes[params.data.loan] || "";  // This gets the correct string value for display
			},
			filterParams: {
			  textFormatter: (input) => {
				const loanTypes = ["", "FHA", "USDA", "Conventional", "VA", "Reverse", "Non-QM"];
				// Convert the input to lowercase for case-insensitive searching
				const formattedInput = input.toLowerCase();
		  
				// Return the corresponding index of the loan type if it matches
				const loanIndex = loanTypes.findIndex(
				  (loanType) => loanType.toLowerCase().includes(formattedInput)
				);
				
				// If no match, return original input (as it might be a number), otherwise return the index as string
				return loanIndex > -1 ? String(loanIndex) : formattedInput;
			  },
			},
		  },		  
		  
		{
			headerName: "ACTIVITY",
			field: "scenario",
			filter: true,
			// floatingFilter: true,
		},
		
		{
			headerName: "BUYER AGENT",
			field: "buyer_agent",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "LISTING AGENT",
			field: "listing_agent",
			filter: true,
			// floatingFilter: true,
		},
		{
			headerName: "TITLE COMPANY",
			field: "title_company",
			filter: true,
			// floatingFilter: true,
		},
		{ headerName: "ACTIONS", cellRenderer: ActionsRenderer, filter: false },
	]);

	const onGridReady = (params) => {
		// Hide all floating filters after grid is ready
		params.api.forEachNode((node) => {
			node.setFloatingFilter(false);
		});
	};

	const onSelectionChanged = (event) => {
		const selectedNodes = event.api.getSelectedNodes();
		setSelectedRows(selectedNodes.map((node) => node.data));
	};

	return (
		<>
			<Dashboard />
			<div className="mx-2">
				<div className="font-bold text-3xl flex items-start ml-10 mt-10 mb-10">
					Customer Management
				</div>
				<div className="ag-theme-alpine mt-2 ml-6 mr-6 border border-grey-300 rounded-lg p-4">
					<div className="flex justify-between items-center mb-4">
						<div>
							<button
								onClick={() => handleDelete(selectedRows.map((row) => row.id))}
								className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
								disabled={selectedRows.length === 0}
							>
								<FaTrash className="inline-block mr-1" />
								Delete Selected
							</button>
							<button
								onClick={handleMassEmail}
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
								disabled={selectedRows.length === 0}
							>
								<FaEnvelope className="inline-block mr-1" />
								Send Mass Email
							</button>
						</div>
						<button
							onClick={() => nav("/addcustomer")}
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
						>
							<FaUserPlus className="mr-2" />
							Add Customer
						</button>
						{/* <UploadBulkModal/> */}
					</div>
					<div
                style={{
                    height: "calc(100vh - 280px)",
                    width: "100%",
                  
                }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    onGridReady={onGridReady}
                    suppressRowClickSelection={true}
                   
                />
            </div>
				</div>
			</div>

			<EmailModal
			isOpen={isModalOpen}
			onClose={handleCloseModal}
			onSendEmail={handleSendEmail}
			selectedEmails={selectedEmails}
			/>

			{/* Status Update Section */}
			{selectedRows.length > 0 && (
				<div className="mx-6 mt-4 p-4 border border-gray-300 rounded-lg">
					<h3 className="text-xl font-bold mb-2">Update Status</h3>
					<select
						value={status}
						onChange={(e) => setStatus(e.target.value)}
						className="border rounded-md p-2 mb-4 w-full"
					>
						<option value="">Select status</option>
						<option value="Active Lead">Active Lead</option>
						<option value="Application Sent">Application Sent</option>
						<option value="Preapproved">Preapproved</option>
						<option value="Underwriting">Underwriting</option>
						<option value="Closed">Closed</option>
					</select>
					<button
						onClick={handleStatusUpdate}
						className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
					>
						Update Status for Selected
					</button>
				</div>
			)}

			{/* Modals */}
			{modalState.isDeleteModalOpen && (
				<Delete
					id={modalState.selectedCustomer.id}
					isOpen={modalState.isDeleteModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isDeleteModalOpen: false }))
					}
					refresh={fetchCustomerList}
				/>
			)}
			{modalState.isEditModalOpen && (
				<EditCustomer
					data={modalState.selectedCustomer}
					isOpen={modalState.isEditModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isEditModalOpen: false }))
					}
					id={modalState.selectedCustomer.id}
					refresh={fetchCustomerList}
				/>
			)}
			{modalState.isViewModalOpen && (
				<ViewCustomer
					data={modalState.selectedCustomer}
					isOpen={modalState.isViewModalOpen}
					closeModal={() =>
						setModalState((prev) => ({ ...prev, isViewModalOpen: false }))
					}
				/>
			)}
		</>
	);
};

export default Listing;
