'use client'

import React, { useState, useEffect, useRef } from 'react'

export default function Component({ isOpen, onClose, onSendEmail, selectedEmails }) {
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const modalRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  const handleSendEmail = async () => {
    const newErrors = {}
    if (!subject.trim()) newErrors.subject = 'Subject is required.'
    if (!body.trim()) newErrors.body = 'Email body is required.'
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    setIsLoading(true)
    try {
      await onSendEmail(selectedEmails, subject, body)
      setIsLoading(false)
      onClose()
    } catch (error) {
      console.error('Failed to send emails:', error)
      setIsLoading(false)
      alert('An error occurred while sending emails. Please try again.')
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="w-full max-w-lg rounded-lg bg-white p-6 shadow-xl"
      >
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Compose Email</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
            disabled={isLoading}
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-500">To: {selectedEmails.join(', ')}</p>
          <input
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value)
              if (errors.subject) setErrors((prev) => ({ ...prev, subject: '' }))
            }}
            className={`mt-2 w-full rounded-md border px-3 py-2 text-sm placeholder-gray-400 focus:outline-none 
              ${errors.subject ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
            disabled={isLoading}
          />
          {errors.subject && (
            <p className="mt-1 text-xs text-red-500">{errors.subject}</p>
          )}
          <textarea
            placeholder="Email body"
            value={body}
            onChange={(e) => {
              setBody(e.target.value)
              if (errors.body) setErrors((prev) => ({ ...prev, body: '' }))
            }}
            className={`mt-2 h-32 w-full rounded-md border px-3 py-2 text-sm placeholder-gray-400 focus:outline-none 
              ${errors.body ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
            disabled={isLoading}
          ></textarea>
          {errors.body && (
            <p className="mt-1 text-xs text-red-500">{errors.body}</p>
          )}
        </div>
        <div className="flex justify-end space-x-2">
            <button
              onClick={handleSendEmail}
              disabled={isLoading}
              className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </>
              ) : (
                'Send Email'
              )}
            </button>
            <button
              onClick={onClose}
              disabled={isLoading}
              className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:opacity-50"
            >
              Close
            </button>
          </div>
      </div>
    </div>
  )
}
