import axiosInstance from "../axiosInstance";
import React, { useState, useEffect, useRef } from "react";
import { X } from "react-feather";

const initialNoteData = {
	title: "",
	description: "",
};

const AddNote = ({ isOpen, closeModal, refresh }) => {
	const [noteData, setNoteData] = useState(initialNoteData);
	const [notesData, setNotesData] = useState([]);

	const modalRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [closeModal]);

	const handleNoteChange = (event) => {
		const { name, value } = event.target;
		setNoteData({ ...noteData, [name]: value });
	};

	const handleClearNote = (event) => {
		event.preventDefault();
		setNoteData(initialNoteData);
		closeModal();
	};

	const handleAddNote = async (event) => {
		event.preventDefault();

		const { title, description } = noteData;
		const payload = {
			title: title,
			note: description,
		};

		try {
			const response = await axiosInstance.post(
				"https://djangobk.lenditcrm.com/customer/note_add/",
				payload,
			);
			console.log("Note added successfully:", response.data);
			alert("Note added successfully");

			const fetchNotesData = async () => {
				try {
					const response = await axiosInstance.get(
						"https://djangobk.lenditcrm.com/customer/note_list/",
					);
					setNotesData(response.data.data);
				} catch (error) {
					console.error("Error fetching data:", error);
				}
			};

			fetchNotesData();
		} catch (error) {
			console.error("Error adding note:", error);
		}
		refresh(true);
		closeModal();
	};

	const handleClickInsideModal = (event) => {
		event.stopPropagation();
	};

	return (
		<div
			className={`fixed inset-0 flex justify-center items-center transition-colors ${
				isOpen ? "visible bg-black/20" : "invisible"
			}`}
			onClick={closeModal}
		>
			<div
				className={`bg-white rounded-xl shadow p-6 transition-all ${
					isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
				}`}
				onClick={handleClickInsideModal}
				style={{ marginTop: "5vh" }}
			>
				<div className="bg-[#028ec8] text-white p-3 text-left text-xl">
					<p className="flex justify-start text-xl font-medium">Add Notes</p>
					{/* <button
            onClick={closeModal}
            className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
          >
            <X />
          </button> */}
				</div>
				<form onSubmit={handleAddNote}>
					<p className="text-lg text-left mt-3 mb-2 ml-4 mr-4">Note Title</p>
					<input
						className="border w-full px-3 py-2 text-gray-700 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ml-4 mr-4"
						type="text"
						name="title"
						placeholder="Title"
						value={noteData.title}
						onChange={handleNoteChange}
					/>
					<p className="text-lg text-left mt-3 mb-2 ml-4 mr-4">
						Note Description
					</p>
					<textarea
						className="border w-full px-3 py-2 text-gray-700 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ml-4 mr-4"
						name="description"
						placeholder="Type Here"
						rows={4}
						value={noteData.description}
						onChange={handleNoteChange}
					/>
					<div className="mt-6 flex justify-end mr-4">
						<button
							type="button"
							className="text-base bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded-lg mr-2"
							onClick={handleClearNote}
						>
							Discard
						</button>
						<button
							type="submit"
							className="text-base bg-[#028ec8] hover:bg-indigo-700 text-white font-bold py-1 px-4 rounded-lg"
						>
							Add
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddNote;
