import React, { useMemo } from "react";
import {
	PieChart,
	Pie,
	Cell,
	ResponsiveContainer,
	Legend,
	Tooltip,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFBB28"]; // Extend colors for more statuses

const PipelineReport = ({ data }) => {
	const pipelineData = useMemo(() => {
		const statusCounts = data.reduce((acc, loan) => {
			const status = loan.status; // Use the dynamic status
			acc[status] = (acc[status] || 0) + 1; // Increment count for the status
			return acc;
		}, {});

		// Transform the statusCounts object into an array for the Pie chart
		return Object.entries(statusCounts).map(([name, value]) => ({
			name,
			value,
		}));
	}, [data]);

	return (
		<div className="bg-white p-6 rounded-lg shadow-md">
			<h2 className="text-2xl font-bold mb-4">Pipeline Report</h2>
			<ResponsiveContainer width="100%" height={400}>
				<PieChart>
					<Pie
						data={pipelineData}
						cx="50%"
						cy="50%"
						labelLine={false}
						outerRadius={150}
						fill="#8884d8"
						dataKey="value"
						label={({ name, percent }) =>
							`${name} ${(percent * 100).toFixed(0)}%`
						}
					>
						{pipelineData.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
					</Pie>
					<Tooltip />
					<Legend />
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default PipelineReport;
