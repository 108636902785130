import React, { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Header from "../Header";
import axiosInstance from "../../axiosInstance";

const Pipeline = () => {
	const [rowData, setRowData] = useState([]);
	const [gridApi, setGridApi] = useState(null);

	const columnDefs = [
		{
			headerCheckboxSelection: true,
			checkboxSelection: true,
			width: 50, // This column allows selecting rows with checkboxes
		},
		{
			headerName: "Customer",
			field: "name",
			filter: true,
			floatingFilter: true,
			flex: 1,
		},
		{
			headerName: "Status",
			field: "status",
			filter: true,
			floatingFilter: true,
			flex: 1,
		},
		{
			headerName: "Last Update",
			field: "last_update", // Matches the "Last Update" column with the "date_modified" field from data
			filter: true,
			floatingFilter: true,
			sort: "desc", // Sorting descending by default
			flex: 1,
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true,
	};

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const onSelectionChanged = () => {
		const selectedRows = gridApi.getSelectedRows();
		console.log("Selected rows:", selectedRows);
	};

	const fetchCustomerList = useCallback(async () => {
		try {
			const response = await axiosInstance.get(
				"https://djangobk.lenditcrm.com/customer/list/",
			);
			if (response.data.code === 200) {
				// Transform the data to match the table columns
				const transformedData = response.data.data.map((customer) => ({
					name: `${customer.first_name} ${customer.last_name}`,
					status:`${customer.status}`,
					// description: `${customer.scenario} - File: ${customer.file_number}`, // You can adjust this as needed
					last_update: new Date(customer.date_modified).toLocaleDateString(), // Formatting the date
				}));

				setRowData(transformedData); // Set the transformed data as rowData
			} else {
				console.error("Error fetching customer list:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}, []);

	useEffect(() => {
		fetchCustomerList();
	}, [fetchCustomerList]);

	return (
		<>
			<Header />
			<div className="p-4">
				<h1 className="text-3xl text-left font-bold mb-4">Pipeline</h1>
				<div className="mb-4">
					<input
						type="text"
						placeholder="Search Deal Reports"
						className="p-2 border rounded w-full"
						onChange={(e) => gridApi?.setQuickFilter(e.target.value)}
					/>
				</div>
				<div
					className="ag-theme-alpine"
					style={{ height: "calc(100vh - 280px)", width: "100%" }}
				>
					<AgGridReact
						columnDefs={columnDefs}
						rowData={rowData}
						defaultColDef={defaultColDef}
						pagination={true}
						paginationPageSize={10}
						rowSelection="multiple"
						onGridReady={onGridReady}
						onSelectionChanged={onSelectionChanged}
					/>
				</div>
			</div>
		</>
	);
};

export default Pipeline;
