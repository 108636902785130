import emailjs from 'emailjs-com';

const sendBulkEmail = async (emails, subject, body, batchSize = 5) => {
  console.log("emails", emails);
  try {
    // Filter out invalid emails
    const validEmails = emails.filter(email => email);
    
    // Break emails into batches
    for (let i = 0; i < validEmails.length; i += batchSize) {
      const batch = validEmails.slice(i, i + batchSize);
      
      const emailPromises = batch.map(email =>
        emailjs.send(
          'service_5lpytnd',
          'template_69wl6x8',
          {
            to_email: email,
            subject,
            message: body,
          },
          'm0ZERoYf4IQTGfBR8'
        )
      );

      await Promise.all(emailPromises);
    }
    
    alert('All emails sent successfully!');
  } catch (error) {
    console.error('Error sending emails:', error);
    alert('An error occurred while sending emails. Please try again.');
  }
};

export default sendBulkEmail;
