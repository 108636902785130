import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '../Context/ToastContext';

const PasswordResetConfirm = () => {
  const { uid, token } = useParams();
  const [loading, setLoading] = useState(false)
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { generateToast } = useToast();

  const [success, setSuccess] = useState('');
  const nav=useNavigate();
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false)
      return;
    }

    try {
      const response = await axios.post(
        `https://djangobk.lenditcrm.com/user/reset/${uid}/${token}/`,
        {  password }
      );
      generateToast(response.data.message,"success")
      setSuccess(response.data.message);
      setLoading(false)
      setTimeout(() => {
        nav("/");
      }, 3000);
      setError('');
    } catch (err) {
      // Handle error response from the API
      const message = err.response?.data?.message || 'An unexpected error occurred.';
      setError(message);
      generateToast(message,"error")
      setLoading(false)
      setSuccess('');
    }
  };

  return (
                     
    <div className="min-h-screen flex">
    {/* Left side - Blue background with CRM name */}
    <div className="w-1/2 bg-blue-500 flex items-center justify-center">
      <div className="text-white text-center">
        <h1 className="text-5xl font-bold">LENDIT CRM</h1>
        <p className="mt-2 text-lg">POWERED BY MAXARON TECHNOLOGIES</p>
      </div>
    </div>
   
    {/* Right side - Form Section */}
    <div className="w-1/2 flex flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-white  rounded-lg p-6 max-w-md w-full space-y-4">
        <h2 className="text-2xl font-semibold text-gray-800 text-center">Reset Password</h2>
        <div className="flex flex-col">
          <label htmlFor="password" className="text-sm font-medium text-gray-700">
            New Password*
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
            className="appearance-none rounded-md block w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="confirm-password" className="text-sm font-medium text-gray-700">
            Confirm Password*
          </label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
            className="appearance-none rounded-md block w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </div>
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        {success && <p className="text-green-500 text-sm text-center">{success}</p>}
        <button
  disabled={loading}
  type="submit"
  className={`w-full inline-flex items-center justify-center px-4 py-2 border border-blue-400 bg-blue-600 text-white ${
    loading ? "cursor-not-allowed opacity-75" : "hover:bg-white hover:text-blue-600"
  } text-lg font-bold rounded-md transition duration-200`}
>
  {loading ? (
    <>
      <svg
        className="animate-spin h-5 w-5 mr-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
        ></path>
      </svg>
      Reseting...
    </>
  ) : (
    "Reset Password"
  )}
</button>

      </form>

      <Link to={"/"}>
						<button
							type="button"
							
							className="font-medium text-blue-600 hover:text-blue-500 text-sm"
						>
							Back to login
						</button>
			</Link>
      
    </div>
    
  </div>
  
 
  );
};

export default PasswordResetConfirm;
