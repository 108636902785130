import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "./AuthToken";

const ProtectedRoute = ({ children }) => {
	const { token } = useAuth();

	return token ? children : <Navigate to="/" replace />;
};

ProtectedRoute.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
