import React, { useState, useEffect } from "react";
import Header from "./Header";
import axiosInstance from "../axiosInstance";
import { FaCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import AddNote from "./AddNotes";

const chunkDataIntoRows = (data, cardsPerRow) => {
	const chunkedData = [];
	let currentRow = [];

	data.forEach((item, index) => {
		if (currentRow.length === cardsPerRow) {
			chunkedData.push(currentRow);
			currentRow = [];
		}
		currentRow.push(item);

		if (index === data.length - 1) {
			chunkedData.push(currentRow);
		}
	});

	return chunkedData;
};

const Note = () => {
	const [notesdata, setNotesData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axiosInstance.get(
					"https://djangobk.lenditcrm.com/customer/note_list/",
				);
				setNotesData(response.data.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchData();
		setRefresh(false);
	}, [refresh]);

	const openAddNoteModal = () => {
		console.log("Add Note button clicked");
		setIsAddNoteModalOpen(true);
	};

	const closeAddNoteModal = () => {
		setIsAddNoteModalOpen(false);
	};

	const handleDelete = (id) => {
		let url;
		url = `https://djangobk.lenditcrm.com/customer/note_delete/?id=${id}`;
		axiosInstance
			.get(url)
			.then((response) => {
				alert("note Deleted");
				console.log(response);
			})
			.catch((error) => {
				console.error(error);
			});
		setRefresh(true);
	};

	const NoteCard = ({ item }) => {
		const [expanded, setExpanded] = useState(false);
		const toggleExpand = () => {
			setExpanded(!expanded);
		};

		const { title, created_at, note } = item;

		return (
			<div className="mt-10 text-left md:w-1/4 mb-4 px-2">
				<div className="Note-row bg-white shadow-md rounded-md p-4 flex flex-col h-full">
					<FaCircle className="text-[#028ec8]" />
					<div className="Note-row-date text-black text-lg ml-8 font-bold">
						{title}
					</div>
					<div className="ml-8">{created_at}</div>
					<div className="Note-row-content flex flex-col gap-1 mt-2">
						{expanded ? (
							<div className="note-container ml-8 overflow-y-auto max-h-40">
								{note}
							</div>
						) : (
							<>
								<div className="note-container ml-8 overflow-y-hidden max-h-40">{`${note.substring(
									0,
									100,
								)}${note.length > 100 ? "..." : ""}`}</div>
								{note.length > 100 && (
									<button
										className="text-blue-600 ml-8 mt-1"
										onClick={toggleExpand}
									>
										Read More
									</button>
								)}
							</>
						)}
						{expanded && (
							<button
								className="text-blue-600 ml-8 mt-1"
								onClick={toggleExpand}
							>
								Read Less
							</button>
						)}
					</div>
					<div className="Note-row-stars flex items-center gap-1 mt-4">
						<span className="text-black ml-8">
							<IoIosStar />
						</span>
						<button
							className="text-red-400"
							onClick={() => handleDelete(item.id)}
						>
							<MdDelete />
						</button>
					</div>
				</div>
			</div>
		);
	};

	const chunkedData = chunkDataIntoRows(notesdata, 3);

	return (
		<>
			<Header />
			<div className="Note container bg-gray-200 flex flex-col ml-64 w-3/4 ag-theme-alpine border border-[#028ec8] rounded-xl p-10 mt-6">
				<div className="Note-header px-4 py-2 flex justify-between items-center">
					<h2 className="mt-4 text-4xl text-blue font-bold">All Notes</h2>
					<div className="flex gap-2">
						<button
							onClick={openAddNoteModal}
							className="bg-[#028ec8] hover:bg-blue-700 text-white text-lg font-medium py-1 px-2 rounded-md focus:outline-none"
						>
							Add Notes
						</button>
					</div>
				</div>
				<div className="Note-body bg-gray-100 rounded-xl text-lg flex-grow py-4 px-2 shadow-inner overflow-y-auto">
					{chunkedData.map((row, rowIndex) => (
						<div
							key={rowIndex}
							className="flex flex-wrap justify-center gap-20"
						>
							{row.map((item, itemIndex) => (
								<NoteCard key={itemIndex} item={item} />
							))}
						</div>
					))}
				</div>
				{isAddNoteModalOpen && (
					<AddNote
						refresh={setRefresh}
						isOpen={isAddNoteModalOpen}
						closeModal={closeAddNoteModal}
					/>
				)}
				{/* {isAddNoteModalOpen && <AddNote isOpen={isAddNoteModalOpen} closeModal={closeAddNoteModal} />} */}
			</div>
		</>
	);
};

export default Note;
