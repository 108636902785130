import React, { createContext, useContext, useState } from "react";

// Create the Toast context
const ToastContext = createContext();

const Toast = ({ isVisible, message, type, onClose }) => {
	const bgColor = {
		success: "bg-green-500",
		error: "bg-red-500",
		info: "bg-blue-500",
		warning: "bg-yellow-500",
	}[type];

	const icon = {
		success: (
			<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
					clipRule="evenodd"
				/>
			</svg>
		),
		error: (
			<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
					clipRule="evenodd"
				/>
			</svg>
		),
		info: (
			<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
					clipRule="evenodd"
				/>
			</svg>
		),
		warning: (
			<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
					clipRule="evenodd"
				/>
			</svg>
		),
	}[type];

	return (
		<div
			className={`fixed top-4 right-4 z-50 transition-all duration-300 ease-in-out transform ${
				isVisible ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
			}`}
			role="alert"
			aria-live="assertive"
		>
			<div
				className={`${bgColor} text-white p-4 rounded-lg shadow-lg flex items-center space-x-3 max-w-md`}
			>
				<div className="flex-shrink-0">{icon}</div>
				<p className="text-sm font-medium flex-grow">{message}</p>
				<button
					onClick={onClose}
					className="flex-shrink-0 ml-auto -mx-1.5 -my-1.5 bg-white bg-opacity-20 text-white rounded-lg focus:ring-2 focus:ring-white p-1.5 hover:bg-opacity-30 inline-flex items-center justify-center h-8 w-8"
					aria-label="Close"
				>
					X
				</button>
			</div>
		</div>
	);
};

// Create a provider component
export const ToastProvider = ({ children }) => {
	const [toast, setToast] = useState({
		isVisible: false,
		type: "success", // Change this to control type (success, error, etc.)
		message: "",
	});

	// Function to show toast with message and success/failure state
	const generateToast = (message, type = "success") => {
		setToast({ isVisible: true, type, message });
		setTimeout(() => {
			setToast({ isVisible: false, type, message: "" });
		}, 3000); // Hide the toast after 3 seconds
	};

	return (
		<ToastContext.Provider value={{ generateToast }}>
			{children}
			<Toast
				isVisible={toast.isVisible}
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ isVisible: false, type: toast.type, message: "" })}
			/>
		</ToastContext.Provider>
	);
};

// Custom hook to use the Toast context
export const useToast = () => useContext(ToastContext);
